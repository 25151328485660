@import 'styles/variables';
@import 'styles/breakpoints';

.footer {
  padding: 20px;
  background-color: $footerBackgroundColor;
  display: none;

  @include above-breakpoint('small') {
    display: block;
  }

  @include above-breakpoint('large') {
    padding: 18px 40px;
  }
}

.nav {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;

  @include above-breakpoint('small') {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &__content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 10px;

    @include above-breakpoint('mediumLarge') {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    @include above-breakpoint('large') {
      gap: 40px;
    }
  }

  p {
    color: $light;
  }
}

.nav-list {
  display: flex;
  gap: 15px;
  list-style: none;
  color: $light;
  padding: 0;
  margin: 0;
  @include above-breakpoint('large') {
    gap: 30px;
  }
}

.nav-list {
  &__item {
    display: flex;
    align-items: center;
    gap: 6px;
    a,
    svg {
      color: $secondaryColor;
      transition: transform 0.4s ease, color 0.4s ease;
    }

    svg {
      margin-left: 6px;
    }

    &:hover {
      text-decoration: underline;
      a,
      svg {
        color: $white;
      }
    }
  }
}
