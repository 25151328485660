@import 'styles/variables';
@import 'styles/breakpoints';
@import 'styles/mixins/lootbox-config';

@include lootboxConfig(360px, 360px, 18px);

.lootbox-card {
  background: $pageDefaultBg;

  @include below-breakpoint('small') {
    width: 286px;
    padding-top: 286px;

    &__background {
      width: 288px;
      height: 287px;
    }
  }

  &__content {
    padding: 16px 20px;
  }

  &::after {
    display: none;
  }
}
