@import 'styles/variables';
@import '../../../styles/breakpoints';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $pageDefaultBg;
  padding: 25px;
  text-align: start;
  overflow: hidden;

  @include above-breakpoint('small') {
    padding: 40px;
  }

  @include above-breakpoint('medium') {
    padding: 60px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: inherit;
    border: 2px solid $light;
    mask-image: linear-gradient(to bottom right, black 30%, transparent 100%);
    opacity: 0.1;
  }
}

.title {
  font-weight: 700;
  font-size: 32px;
  color: $light;
  text-transform: uppercase;
  margin-bottom: 15px;

  @include above-breakpoint('small') {
    font-size: 44px;
    margin-bottom: 20px;
  }

  @include above-breakpoint('medium') {
    font-size: 48px;
  }

  b {
    color: $aboutColorSecondary;
  }
}

.content {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  color: $light;
  flex-grow: 1;

  @include above-breakpoint('small') {
    font-size: 20px;
  }

  @include above-breakpoint('medium') {
    font-size: 22px;
  }
}

.footer:not(:empty) {
  margin-top: auto;
  padding-top: 30px;
}

.main-text {
  line-height: 24px;

  @include above-breakpoint('small') {
    line-height: 28px;
  }

  @include above-breakpoint('medium') {
    line-height: 30px;
  }
}

.graphics {
  z-index: 0;
}
