@import 'styles/variables';
@import '../../styles/breakpoints';

button.tab {
  font-weight: 600;
  height: 48px;
  font-size: 20px;
  padding: 14px 20px;
  border-radius: 1000px;
  background: $pageDefaultBg;
  color: $light;

  @include above-breakpoint('small') {
    height: 64px;
    padding: 18px 32px;
    font-size: 24px;
  }

  &--active {
    background: linear-gradient(0deg, #ffffff, #ffffff), #6dfbff;
    color: $primaryColor;
  }
}
