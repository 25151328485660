@import 'styles/variables';
@import '../../styles/breakpoints';

.map-container {
  top: $menuMobileHeight;
  height: calc(100% - $menuMobileHeight);
  width: 100%;
  position: relative;

  @include above-breakpoint('extraSmall') {
    top: $menuDesktopHeight;
    height: calc(100% - $menuDesktopHeight);
  }

  .mapboxgl-control-container {
    .mapboxgl-ctrl {
      &.mapboxgl-ctrl {
        background: none;

        button {
          display: none;
        }

        a {
          color: $thirdColor;
        }
      }
    }
  }
}

.map-route-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
  background: linear-gradient(180deg, #0e2659 -25%, transparent 20%, transparent 80%, #0e2659 125%);
}

.map-route-container {
  position: relative;

  &-wrapper {
    overflow: hidden;
  }

  // TODO: create HOC wrapper component
  .page-wrapper {
    position: absolute;
    left: 0;
    z-index: 3;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 1116px;
    width: 100vw;

    @media (min-width: $screenSize680px) {
      left: 50%;
      transform: translateX(-50%);
      top: 90px;
      width: calc(100vw - 40px);
      padding-top: 20px;
    }

    @media (min-width: $screenSize1280px) {
      height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
      padding-top: 50px;
    }

    &--fullscreen {
      max-width: unset;

      @media (min-width: $screenSize680px) {
        left: 50%;
        transform: translateX(-50%);
        top: 90px;
        width: calc(100vw - 40px);
        padding-top: 20px;
      }
    }

    .content {
      padding: 0 0 0 38px;

      @media (min-width: $screenSize680px) {
        padding: 0 0 0 64px;
      }
    }
  }

  &__top {
    margin-top: 7px;

    @media (min-width: $screenSize680px) and (min-height: $screenSize680px) {
      margin-top: 0px;
    }
  }

  &__middle {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    height: 100%;

    .venue-card {
      position: absolute;
      right: 0;
      display: none;
      margin: 0;
      top: auto;
      bottom: auto;
      transform-origin: top right;

      @media (min-width: $screenSize680px) {
        display: flex;
      }

      @media (max-height: $screenSize500px) {
        right: 20px;
        margin-top: 20px;
        transform: scale(0.4) !important;
      }
    }
  }

  &__bottom {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
    min-height: 80px;
    justify-content: end;
    bottom: -26px;
    flex-direction: row;
    align-items: flex-end;

    &-golem-logo,
    &-logo {
      width: 140px;
      object-fit: contain;
      height: 68px;

      @include above-breakpoint('mediumSmall') {
        width: 200px;
      }
    }
  }
}

.map-route-nft-card {
  align-self: flex-start;
  width: 360px;
  transform-origin: top right;

  &__button {
    width: 144px;
    margin: 4px auto;
  }
}

.venue_icon_image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-64px, -64px);
  margin: auto;
  width: 128px;
  height: 128px;
}

.map-filters {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  max-width: 100%;
  grid-row-gap: 20px;
  margin-bottom: 20px;

  @media (min-width: $screenSize880px) {
    max-width: 567px;
  }

  @media (min-width: 3840px) {
    max-width: 703px;
  }

  &__filter-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }
}
