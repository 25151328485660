@import 'styles/variables';

.profile-section {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: $pageDefaultBg;

    li:not(:last-child) {
      border-bottom: 1px solid $textsBackgroundColor;
    }

    &__link-icon {
      width: 32px;
      height: 32px;
    }
  }
}
