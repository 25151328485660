@import 'styles/variables';

:export {
  backgroundColor: $inputBackgroundColor;
  optionHoverBackgroundColor: lighten($inputBackgroundColor, 10);
  disabledBackgroundColor: $inputDisabledBackgroundColor;
  inputDisabledColor: $inputDisabledColor;
  inputIconColor: $secondaryColor;
  inputIconHoverColor: $white;
  height: $inputHeight;
  padding: $inputPadding;
  borderRadius: $inputRadius;
  fontSize: $inputFontSize;
  inputColor: $inputColor;
  placeholderColor: $inputPlaceholderColor;
  border: 1px solid $secondaryColor !important;
}

.input-form-field {
  position: relative;
  color: $inputColor;
  font-size: $inputFontSize;

  &--primary &__input {
    background-color: $inputBackgroundColor;
  }

  &--secondary &__input {
    color: $inputSecondaryColor;
    background-color: $inputSecondaryBackgroundColor;
  }

  &__wrapper {
    width: 100%;
  }

  &__label {
    display: block;
    margin-bottom: 10px;
    color: $inputLabelColor;

    &--hidden {
      position: absolute;
      z-index: -10;
      height: 0;
      opacity: 0;
    }
  }
}
