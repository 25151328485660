@import '../../../styles/breakpoints';

$graphicsOriginalHeight: 478px;
$graphicsMobileScale: 0.75;

$gameImageWidth: 456px;
$gameImageHeight: 446px;
$percentageLeftWidth: 248px;
$percentageLeftHeight: 504px;
$nftCardWidth: 357px;
$nftCardHeight: 480px;
$percentageRightWidth: 192px;
$percentageRightHeight: 504px;
$tradingRoyaltiesWidth: 357px;
$tradingRoyaltiesHeight: 454px;

@keyframes floatLeft {
  from {
    transform: translateX(2px);
  }
  65% {
    transform: translateX(0px);
  }
  to {
    transform: translateX(2px);
  }
}

@keyframes floatRight {
  from {
    transform: translateX(-2px);
  }
  65% {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-2px);
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(32, 74, 148, 0.5), rgba(32, 74, 148, 0.5)),
    url('../../../assets/landing/players/players-section-background.jpg'),
    linear-gradient(104.43deg, #20438e 0%, #2274b4 100%), #051943;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;

  @include above-breakpoint('small') {
    padding: 80px 0 60px 0;
  }

  @include above-breakpoint('medium') {
    padding: 120px 0;
  }
}

.content-wrapper {
  max-width: 100%;
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 30px;

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
}

.stores {
  position: absolute;
  right: 32px;
  bottom: 20px;
  display: flex;
  gap: 14px;
  width: 340px;
  transform: translateY(100%);

  &__download {
    flex-grow: 1;
    flex-basis: 0;

    img {
      width: 100%;
    }
  }
}

.title {
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.main-text {
  max-width: 952px;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 20px;

  @include above-breakpoint('medium') {
    margin-bottom: 40px;
  }
}

.trading-royalties {
  margin-left: 20px;
}

.slide-scroll-icon {
  margin-top: 40px;

  path:first-child {
    animation: floatLeft 2s ease infinite;
  }

  path:last-child {
    animation: floatRight 2s ease infinite;
  }
}

.game-stores {
  width: $gameImageWidth * $graphicsMobileScale;
  height: $gameImageHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $gameImageWidth;
    height: $gameImageHeight;
  }
}

.percentage-left {
  width: $percentageLeftWidth * $graphicsMobileScale;
  height: $percentageLeftHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $percentageLeftWidth;
    height: $percentageLeftHeight;
  }
}

.nft-card {
  width: $nftCardWidth * $graphicsMobileScale;
  height: $nftCardHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $nftCardWidth;
    height: $nftCardHeight;
  }
}

.percentage-right {
  width: $percentageRightWidth * $graphicsMobileScale;
  height: $percentageRightHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $percentageRightWidth;
    height: $percentageRightHeight;
  }
}

.trading-royalties {
  width: $tradingRoyaltiesWidth * $graphicsMobileScale;
  height: $tradingRoyaltiesHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $tradingRoyaltiesWidth;
    height: $tradingRoyaltiesHeight;
  }
}
