@import 'styles/variables';

.profile-navigation-link {
  min-width: 140px;
  padding: 10px 14px;
  border-bottom: 3px solid;
  border-color: transparent;

  &:hover {
    cursor: pointer;
    border-color: $menuLinkBgHoverColor;
  }

  &__tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  p {
    position: relative;
    width: 60px;
    font-size: 16px;
    line-height: 19px;
    color: $white;
  }

  svg {
    height: 32px;
    width: 32px;
  }
}

.navigation-active {
  box-shadow: none;

  > div {
    border-color: $menuLinkBgHoverColor;
  }
}

.profile-navigation-link--disabled {
  opacity: 0.5;
}
