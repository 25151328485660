@import 'styles/variables';

.checkout-funds-modal {
  color: $white;
  text-align: center;

  &__subtitle {
    font-weight: 700;
    font-size: 24px;
    margin: 0 0 14px 0;

    span {
      margin-left: 5px;
      color: $secondaryColor;
    }
  }

  &__content {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 20px 0;
  }

  .form-field__label-caption {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-field__label input {
    font-size: 16px;
  }

  .form-field__suffix {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  &--no-funds .modal__content {
    width: 650px;
    max-width: calc(var(--vw, 1vw) * 100 - 10px);
  }

  &--ramp .modal__content {
    width: 650px;
    max-width: calc(var(--vw, 1vw) * 100 - 10px);
    max-height: calc(var(--vh, 1vh) * 100 - 15px);
  }
}
