@import '../../../styles/breakpoints';
@import 'styles/variables';

.section {
  padding: 50px 0 30px;

  @include above-breakpoint('small') {
    padding: 80px 0 40px;
  }

  @include above-breakpoint('medium') {
    padding: 120px 0 60px;
  }
}

.title {
  text-align: center;
  margin-bottom: 30px;

  @include above-breakpoint('small') {
    margin-bottom: 40px;
  }

  @include above-breakpoint('medium') {
    margin-bottom: 60px;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.roadmap-sections {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(12, [col-start] 1fr);

  @include above-breakpoint('small') {
    gap: 20px 40px;
  }
}

.roadmap-section {
  grid-column: span 12;

  @include above-breakpoint('small') {
    grid-column: span 6;
  }

  @include above-breakpoint('medium') {
    grid-column: span 4;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: $aboutColorSecondary;

    @include above-breakpoint('small') {
      margin-bottom: 30px;
      font-size: 26px;
    }

    @include above-breakpoint('medium') {
      font-size: 30px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__list-item {
    display: flex;
    margin-bottom: 14px;
    color: #527cd0;
    align-items: center;

    &--checked {
      color: $light;
    }
  }

  &__list-item &__list-dot {
    margin-right: 10px;
  }
}

.roadmap-summary {
  display: flex;
  margin: 0 auto;
  padding: 12px 20px 12px 16px;
  gap: 20px;
  background: $primaryColor;
  border-radius: 100px;

  &__item {
    display: flex;
    position: relative;
    align-items: end;
    line-height: unset;

    @include above-breakpoint('small') {
      align-items: inherit;
    }
  }

  &__dot {
    margin-right: 10px;
    margin-top: 4px;
  }
}
