@use 'sass:math';
@import '../variables';
@import '../breakpoints';

@mixin lootboxConfig($lootboxCardBackgroundHeight, $lootboxCardBackgroundWidth, $lootboxCardCloudHeight) {
  .lootbox-card {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    width: $lootboxCardBackgroundWidth;
    padding-top: $lootboxCardBackgroundHeight;
    background: linear-gradient(180deg, rgba(20, 52, 120, 0) 79.88%, $pageDefaultBg 100%), $pageDefaultBg;
    transform: translateZ(1000px);
    transform-style: preserve-3d;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $pageDefaultBg;
      border-radius: 16px;
    }

    &__background {
      position: absolute;
      z-index: 1;
      left: -1px;
      top: -1px;
      width: $lootboxCardBackgroundWidth + 2px;
      height: $lootboxCardBackgroundHeight + 1px;
      background-size: cover;
      background-position: center;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      left: 50%;
      display: block;
      transform: translateZ(2px) translate(-50%, -100%);
      width: calc(100% + math.div($lootboxCardCloudHeight, 2));
      height: $lootboxCardCloudHeight + 2px;
      background-image: url('../../assets/lootbox/lootbox-card-blue-background.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform-style: preserve-3d;
    }

    &__content {
      position: relative;
      z-index: 3;
    }
  }
}
