@import 'styles/variables';

.list-dot {
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 4px solid #143478;

  &--checked {
    position: relative;
    background: rgba(109, 251, 255, 0.15);
    border: 0;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background: rgba(109, 251, 255, 0.25);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background: $aboutColorSecondary;
    }
  }
}
