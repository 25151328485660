@import 'styles/variables';
@import 'styles/breakpoints';

$mainPageContainerPadding: 20px;

.landing-page {
  background-color: $primaryColor !important;

  :global {
    .landing-page-container {
      width: 1280px + 2 * $mainPageContainerPadding;
      max-width: 100%;
      padding-left: $mainPageContainerPadding;
      padding-right: $mainPageContainerPadding;
      margin-left: auto;
      margin-right: auto;
    }

    .landing-page-dark-wrapper {
      background-color: #051943;
    }

    .landing-main-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $light;

      @include above-breakpoint('small') {
        font-size: 20px;
        line-height: 28px;
      }

      @include above-breakpoint('medium') {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

  h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 52px;
    line-height: 48px;
    font-weight: 700;
    color: $light;
    text-transform: uppercase;
    text-shadow: -4px 4px 4px rgba(15, 35, 83, 0.25);

    @include above-breakpoint('small') {
      font-size: 48px;
      line-height: 48px;
    }

    @include above-breakpoint('medium') {
      margin-bottom: 30px;
      font-size: 98px;
      line-height: 72px;
    }
  }
}

.content {
  position: relative;
  z-index: 0;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 28px;
    color: $light;
    text-transform: uppercase;
    margin-bottom: 15px;

    b {
      color: $aboutColorSecondary;
    }

    @include above-breakpoint('small') {
      font-size: 44px;
      line-height: 44px;
      margin-bottom: 20px;
    }

    @include above-breakpoint('medium') {
      font-size: 48px;
      line-height: 48px;
    }
  }
}

.sections {
  position: relative;
  z-index: 3;
}

.footer {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px 20px;
  width: 100%;
  height: 289px;
  background-color: $pageFooterBackgroundColor;

  @include above-breakpoint('small') {
    height: 372px;
    padding: 0 20px;
  }

  p {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 26px;
    color: $light;

    @include above-breakpoint('small') {
      flex-direction: row;
      font-size: 36px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;

      @include above-breakpoint('small') {
        margin-bottom: 66px;
      }
    }
  }

  button {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: $light;
    border-radius: 32px;
    border: 2px solid $green;
    margin-left: 20px;
    margin-top: 14px;
    height: 52px;
    width: 228px;
    padding: 5px 12px;
    background: none;

    @include above-breakpoint('small') {
      margin-top: 0;
      font-size: 24px;
      height: 64px;
      width: 250px;
    }

    &:hover {
      transition: transform 0.2s;
      cursor: pointer;
    }
  }

  img {
    height: 43px;

    @include above-breakpoint('small') {
      height: 63px;
    }
  }
}

.nft-portal-link {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 32px;
    opacity: 0;
    background-color: $green;
    transition: opacity 0.4s ease;
  }

  &:hover::before {
    opacity: 1;
  }
}
