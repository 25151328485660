@use 'sass:map';
@use 'sass:math';
@import 'styles/variables';

$handleSize: 42px;
$trackHeight: 10px;
$handleMargin: math.div($handleSize - $trackHeight, 2) * (-1);

:export {
  trackBackground: linear-gradient(90deg, $green 0%, #005e37 89.66%);
  handleSize: $handleSize;
  trackHeight: $trackHeight;
  handleMargin: $handleMargin;
  railBackground: $primaryColor;
}

.slide-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  height: $handleSize;
  min-height: $handleSize;
}

.slider {
  &__button {
    display: flex;
    align-items: center;
  }
}
