@import 'styles/variables';

.hamburger {
  cursor: pointer;
}

.line {
  display: block;
  width: 22px;
  height: 2px;
  background-color: $white;
  margin: 5px 0;
  border-radius: 4px;
  transition: transform 0.2s ease, opacity 0.2s ease;

  &:last-child {
    width: 14px;
  }
}

.hamburger--active .line:nth-child(1),
.hamburger--active .line:nth-child(2),
.hamburger--active .line:nth-child(3) {
  width: 22px;
}

.hamburger--active .line:nth-child(2) {
  opacity: 0;
}

.hamburger--active .line:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger--active .line:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}
