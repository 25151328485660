@import '../../../styles/breakpoints';
@import 'styles/variables';

.container {
  padding: 30px 0 50px 0;
  margin: 0 auto;

  @include above-breakpoint('small') {
    padding: 40px 0 80px 0;
  }

  @include above-breakpoint('mediumLarge') {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(24, [col-start] 1fr);
    padding: 80px 0 120px 0;
  }
}

.main-text {
  margin-bottom: 30px;

  @include above-breakpoint('medium') {
    margin-bottom: 60px;
  }
}

.link {
  color: $light;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 40px;

  img {
    margin-left: 12px;
    transition: transform 0.2s ease;
  }

  &:hover img {
    transform: translateX(5px);
  }
}

.summary {
  grid-column: span 12;
  margin-right: auto;

  h2 {
    text-transform: initial;
  }

  @include above-breakpoint('medium') {
    grid-column: span 7;
  }
}

.carousel-container {
  grid-column: span 12;
  max-width: 100%;

  @include above-breakpoint('medium') {
    grid-column: span 17;
  }
}

.slider {
  :global {
    .slick-slide {
      min-width: 280px;
      margin-right: 10px;
      padding-top: 40px;
      padding-bottom: 30px;

      @include above-breakpoint('small') {
        padding-bottom: 40px;
        margin-right: 20px;
      }

      @include above-breakpoint('mediumLarge') {
        padding-top: 0;
      }
    }

    .slick-dots {
      bottom: -30px;

      @include above-breakpoint('small') {
        bottom: -40px;
      }
    }
  }
}
