.backdrop {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  animation-name: fadeOut;
  animation-duration: 0.55s;

  &.visible {
    position: absolute;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    animation-name: fadeIn;
    animation-duration: 0.25s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
  }
  99% {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
  }
  100% {
    opacity: 0;
  }
}
