@import 'styles/variables';
@import '../../../styles/breakpoints';

.progress-modal {
  margin: 10px 0;

  &__text {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  &__content {
    width: 260px;
    height: 260px;
    max-width: 100%;

    @include above-breakpoint('small') {
      width: 360px;
      height: 360px;
    }
  }

  &__lootbox > video {
    position: relative;
    width: 100%;
    padding-bottom: 0;
  }
}

.lootbox-main-view {
  &__title,
  &__subtitle {
    max-width: calc(100% - 40px);
    margin: 0 auto;

    @include above-breakpoint('small') {
      max-width: 728px;
    }
  }

  &__title {
    margin: 10px auto;

    @include above-breakpoint('small') {
      margin: 12px auto 14px;
    }
  }

  &__faq-wrapper {
    &:not(:last-child) {
      border-bottom: 1px solid $pageDefaultBg;
    }
  }

  &__subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $light;
    margin-bottom: 20px;

    @include above-breakpoint('small') {
      margin-bottom: 40px;
    }
  }

  &__tabs-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 0 30px 0;

    @include above-breakpoint('small') {
      padding: 20px 0 50px 0;
    }
  }

  &__tabs {
    width: 588px;
    max-width: 100%;
  }

  &__owned-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    @include above-breakpoint('small') {
      gap: 14px;
    }
  }

  &__card {
    overflow: hidden;
    padding: 30px;

    @include below-breakpoint('small') {
      padding: 30px 20px;
      border-radius: 0;
    }
  }

  &__lootboxes {
    position: relative;
    transition: height 0.3s ease;
  }

  &__section {
    width: 100%;

    &--loaded {
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &--hidden {
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: 0;
      transform: translateX(-100%);
    }

    &--visible {
      position: relative;
      opacity: 1;
      z-index: 1;
      transform: translateX(0);
    }
  }

  &__faq-title {
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    text-transform: uppercase;
    margin: 60px 0 30px;

    @include above-breakpoint('small') {
      font-size: 32px;
      line-height: 42px;
    }
  }

  &__faq {
    width: 788px;
    max-width: 100%;
    margin: 0 auto;
  }
}
