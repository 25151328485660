@import '../variables';

.contact {
  background-color: $pageDefaultBg;

  .contact_inner_content {
    @media (min-width: $screenSize680px) {
      padding: 60px 90px;
    }
  }

  .offices_header {
    margin: 0;
    padding: 16px;
  }

  .offices {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .contact-office-card {
      flex: 1 1 100%;
    }

    @media (min-width: 1061px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16;
      justify-content: space-between;

      .contact-office-card {
        flex: 0 0 32%;
      }
    }
  }

  &_form-contact-container {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 1061px) {
      flex-direction: row;
    }
  }
}

.contact {
  .subpage_header_wrapper {
    position: relative;
    overflow: hidden;

    @media (max-width: 1061px) {
      &:after {
        border-radius: 100%;
        position: absolute;
        background-color: rgba(31, 64, 140);
        right: 0;
        left: 0;
        bottom: 0;
        top: 115px;
        width: 100%;
        content: '';
        z-index: 0;
      }
    }
  }

  .header_highlighted_text {
    text-align: center;
    font-size: 130px;
    color: white;
    font-weight: bold;
    font-style: italic;
    -webkit-text-stroke: 1px #86fbff;
    text-shadow: 0px 0px 15px #86fbff;
    position: absolute;
    bottom: 1.5vw;
    transform: rotate(-3deg);
    width: 100%;
    z-index: 1;

    @media (max-width: 1380px) {
      font-size: 90px;
    }

    @media (max-width: 786px) {
      font-size: 50px;
    }
  }

  .contact_background_image {
    width: 100%;
    min-height: 180px;
    border-top-right-radius: $cornerRounding;
    border-top-left-radius: $cornerRounding;
    object-fit: cover;

    @media (min-width: 1061px) {
      object-fit: fill;
      flex-direction: row;
    }
  }

  .contact_form_wrapper {
    align-items: center;
    position: relative;
    padding: 16px 16px 0 16px;
  }

  .form_error {
    bottom: 40px;
    color: #ff4d4d;
    margin: 5px 0;
  }

  .contact_form_header {
    font-size: 54px;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 700;
    color: white;
    font-style: italic;
    margin-top: 0;
  }

  .contact_form {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding: 16px 0;
    width: 100%;

    .regular_input {
      background-color: $inputBackgroundColor;
      border-radius: $inputRadius;
      border: none;
      color: white;

      &::placeholder {
        color: $secondaryColor;
      }
    }

    .select,
    .contact-input {
      flex: 0 0 100%;

      @media (min-width: $screenSize680px) {
        flex: 1 0 calc(50% - 15px);
      }
    }

    .contact_textarea {
      width: 100%;
      height: 96px;
      padding: $inputPadding;
      resize: none;
    }

    .contact_form_btn_wrapper {
      width: 100%;
    }

    .contact_form_submit {
      margin-top: 10px;
      display: block;
      margin-left: auto;
      width: 96px;
      height: 32px;
      border-radius: $inputRadius;
      background-color: transparent;
      border-color: $secondaryColor;
      border: 2px solid;
      color: $secondaryColor;
      text-transform: uppercase;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        transition: 0.3s;
        color: $thirdColor;
        border-color: $thirdColor;
      }
    }
  }

  .content {
    height: 100%;
    width: 100%;
    border-radius: $cornerRounding;
    position: relative;

    .bg {
      height: 100%;
      width: 100%;
      background-size: 100% auto;
      border-radius: $cornerRounding;
    }

    .text {
      position: absolute;
      top: 45%;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 128px;
      font-style: italic;
      color: white;
      font-weight: 700;
      transform-origin: 0 0;
      transform: rotate(-4deg);
      text-shadow: 0 0 16px #09ceff;
      margin: 0;

      @media (max-width: 1380px) {
        font-size: 80px;
      }
    }
  }

  .pr_contact_wrapper {
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;

    img {
      border-radius: 16px;
    }

    .press_kit_button {
      top: 10px;
      right: 0;
      height: 32px;
      border-radius: 32px;
      outline: 2px solid white;
      color: white;
      border: none;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;

      &:hover {
        transition: 0.2s;
        color: $inputPlaceholderColor;
        background-color: white;
      }
    }

    .contact_details_wrapper {
      display: flex;
      gap: 20px;

      .pr_contact_photo {
        width: 96px;
        height: 96px;
        flex: 0 0 96px;
      }

      .personal_details {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding: 5px 0px;

        .name {
          color: white;
          font-size: 24px;
          flex: 1 1 auto;
        }

        .mail {
          color: $secondaryColor;
          font-size: 18px;
        }

        .phone_number {
          color: $secondaryColor;
          font-size: 18px;
        }
      }
    }
  }
}
