@import '../../../../styles/variables';
@import '../../../../styles/breakpoints';

.lootbox-title,
.lootbox-subtitle {
  font-weight: 700;
}

.lootbox-title {
  font-size: 26px;
  text-transform: uppercase;

  @include above-breakpoint('small') {
    font-size: 32px;
  }
}

.lootbox-subtitle {
  font-size: 16px;

  @include above-breakpoint('small') {
    font-size: 18px;
  }
}

.lootbox-title-section {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 16px;
  width: 100%;
  text-align: center;
  color: $light;
  transform: translateZ(3px);
  transform-style: preserve-3d;

  @include above-breakpoint('small') {
    top: 24px;
  }
}
