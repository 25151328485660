@import 'styles/variables';
@import '../../../styles/breakpoints';
@import '../../../styles/animations/glitch-animation';

.section {
  padding: 0 0 50px 0;
  background: #051943;

  @include above-breakpoint('small') {
    padding: 0 0 80px 0;
  }
}

.title,
.subtext {
  text-align: center;
  margin-bottom: 20px;
}

.subtext {
  width: 857px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;

  @include above-breakpoint('medium') {
    margin-bottom: 60px;
  }
}

.partners-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.partners-item-container {
  flex-grow: 1;
  flex-basis: 150px;
  max-width: calc(50% - 40px);
  padding: 10px 0;

  @include above-breakpoint('extraSmall') {
    flex-basis: calc(33.33% - 40px);
    max-width: calc(33.33% - 40px);
    min-width: 200px;
    padding: 20px 0;
  }

  @include above-breakpoint('small') {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: calc(25% - 40px);
    max-width: calc(25% - 40px);
    min-width: 270px;
    margin: 0 10px;
    padding: 42px 0;
  }
}

.partners-item {
  position: relative;
  display: flex;
  justify-content: center;
  transition: filter 0.2s ease, color 0.2s ease;
  color: $light;

  &:hover {
    filter: drop-shadow(-9px 9px 0 rgb(0, 4, 48, 0.3));
    color: $landLordGoColorSecondary;
  }
}

.partners-list {
  position: absolute;
  z-index: -1000;
  user-select: none;
  pointer-events: none;
  opacity: 0;

  @include above-breakpoint('extraSmall') {
    position: unset;
    z-index: unset;
    user-select: unset;
    pointer-events: unset;
    opacity: 1;
  }
}

.partners-slider {
  @include above-breakpoint('extraSmall') {
    display: none !important;
  }

  .partners-item {
    margin: 27px auto;
    width: calc(100% - 60px);

    svg {
      height: auto;
      max-height: 60px;
      width: 100%;
    }
  }
}

ul.partners-slider__dots {
  bottom: -20px;
}
