.white-paper .content {
  overflow: initial;
}

.white-paper__sections {
  margin: 16px;
  display: flex;
  flex-direction: column;

  &-inner {
    margin-left: 16px;
  }

  ul,
  li,
  p {
    font-size: 16px;
    line-height: 18.4px;

    @media (min-width: $screenSize1380px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  ul {
    padding-left: 20px;
    font-size: 16px;
  }

  h2 {
    color: $white;
    font-size: 16px;

    @media (min-width: $screenSize1380px) {
      font-size: 34px;
      line-height: 46px;
    }
  }

  img:not(.collapse__caption-arrow) {
    display: flex;
    margin: 0 auto;
    border-radius: 28px;
  }

  img + img {
    margin: 16px auto !important;
  }

  .white-papper-game-stats span {
    color: $redDark;
  }
}
