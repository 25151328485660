@use 'sass:map';
@import 'styles/variables';
@import '../../../styles/mixins/button-config';

.notification {
  display: flex;
  justify-content: space-between;
  width: calc(var(--vw, 1vw) * 100 - 30px);
  padding: 14px 15px;
  border: 2px solid $secondaryColor;
  border-radius: 16px;
  box-shadow: 0px 4px 16px rgba($black, 0.5);
  background-color: $backgroundColorDark;
  color: $light;
  font-weight: 700;

  @media (min-width: $screenSize680px) {
    align-items: center;
    padding: 14px 30px 14px 20px;
    border-radius: 100px;
    width: auto;
  }

  &--success .icon-wrapper {
    color: map.get(map.get($buttonColorConfig, 'primary'), 'color');
    background: map.get(map.get($buttonColorConfig, 'primary'), 'background');
  }

  &--error .icon-wrapper {
    color: map.get(map.get($buttonColorConfig, 'accent'), 'color');
    background: map.get(map.get($buttonColorConfig, 'accent'), 'background');
  }

  &--info .icon-wrapper {
    color: map.get(map.get($buttonColorConfig, 'disabled'), 'color');
    background: map.get(map.get($buttonColorConfig, 'disabled'), 'background');
  }

  &--warning .icon-wrapper {
    color: map.get(map.get($buttonColorConfig, 'warning'), 'color');
    background: map.get(map.get($buttonColorConfig, 'warning'), 'background');
  }
}

.message {
  font-size: 20px;

  @media (min-width: $screenSize680px) {
    font-size: 24px;
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  min-width: 26px;
  margin-right: 12px;
  border-radius: 50%;

  @media (min-width: $screenSize680px) {
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin-right: 15px;
  }
}

.type-icon {
  width: 16px;
  height: 16px;

  @media (min-width: $screenSize680px) {
    width: 19px;
    height: 19px;
  }
}

.close-button {
  display: flex;

  @media (min-width: $screenSize680px) {
    align-items: center;
  }
}

.close-icon {
  width: 16px;
  height: 16px;
  margin-left: 20px;
  color: $light;

  @media (min-width: $screenSize680px) {
    margin-left: 40px;
  }
}
