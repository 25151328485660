@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.fly-money-image {
  width: 274px;
  min-height: 300px;
  height: auto;

  @include above-breakpoint('medium') {
    width: 513px;
    margin-right: 30px;
  }
}

.nft-card-wrapper {
  min-height: 200px;
}

.nft-card {
  width: 300px;
  height: auto;
  margin-top: -30px;

  @include above-breakpoint('medium') {
    margin-top: -170px;
  }
}

.royalties-item {
  position: relative;
  display: flex;
  min-width: 265px;
  margin: 15px 0;
  padding: 10px 20px;
  border-radius: 20px;
  background: rgba(67, 117, 197, 0.1);

  @include above-breakpoint('medium') {
    padding: 20px 30px;
    border-radius: 30px;
  }

  &__value {
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    color: $light;
    margin-right: 15px;

    @include above-breakpoint('medium') {
      font-size: 62px;
      line-height: 52px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: $aboutColorSecondary;

    @include above-breakpoint('medium') {
      font-size: 30px;
      line-height: 24px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 20px;
    background: linear-gradient(
      120.18deg,
      rgba(65, 149, 210, 0) -0.05%,
      #4195d2 19.74%,
      rgba(65, 149, 210, 0) 42.14%,
      #4195d2 69.22%,
      rgba(65, 149, 210, 0) 99.95%
    );
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    @include above-breakpoint('medium') {
      border-radius: 30px;
    }
  }
}
