@use 'sass:math';
@import 'styles/variables';
@import '../../styles/breakpoints';

.page {
  position: absolute;
  z-index: 1;
  top: $menuMobileLandingHeight;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - $menuMobileLandingHeight);

  @include above-breakpoint('extraSmall') {
    top: $menuDesktopHeight;
    height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
  }

  &--regular {
    background-color: $pageDefaultBg;
  }

  &--dark {
    background-color: $backgroundColorDark;
  }

  &--scrollable {
    overflow-y: auto;
  }
}

.page--banner-safe-space {
  padding-top: $freeNFTBannerMobile;

  @include above-breakpoint('extraSmall') {
    padding-top: $freeNFTBanner;
  }
}

.banner {
  position: absolute;
  top: 0;
  z-index: 1;

  &--sticky {
    position: fixed;
    top: $menuMobileLandingHeight;

    @include above-breakpoint('extraSmall') {
      top: $menuDesktopHeight;
    }
  }
}
