@import 'styles/breakpoints';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 50px 0 80px;
  margin: 0 auto;
  background-color: #051943;

  @include above-breakpoint('small') {
    padding: 80px 0;
  }

  @include above-breakpoint('medium') {
    gap: 40px;
    padding: 120px 0 80px;
  }
}

.summary {
  text-align: center;
  width: 998px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.slider {
  :global {
    .slick-track {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      padding-top: 20px;
    }

    .slick-dots {
      bottom: -40px;
    }
  }
}

.card {
  margin: 0 10px;
  @include above-breakpoint('medium') {
    margin: 0 15px;
  }
}
