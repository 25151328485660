.profile {
  .content {
    height: 100%;
    width: 100%;
    padding: 0 20px 0 64px !important;

    .profile_scroll {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      //overflow: scroll;
      height: 100%;

      .left_wrap {
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px 20px 0 20px;
        background-color: $textsBackgroundColor;
        height: 100%;
        .profile_data {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-top: 60px;
          padding-bottom: 60px;
          .avatar {
            position: relative;
            height: 117px;
            width: 117px;
            border-radius: 117px;
            background-image: linear-gradient(-45deg, #0A159B 0%, #3668FF 38.49%, #5AB0FF 76.32%, #A2D9FF 100%);
            background-size: contain;
            .refresh_btn {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              position: absolute;
              height: 32px;
              width: 32px;
              border-radius: 32px;
              bottom: -5px;
              right: -5px;
              background: linear-gradient(180deg, #01E48A 0%, #00AB64 100%);
            }
          }
          .inputs_wrap {
            display: flex;
            flex-direction: column;
            margin: 0 15px;
            width: 100%;
            .profile_input {
              margin: 5px 0;
              width: 100%;
            }
          }
        }
        .wallet_wrap {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 30px;
          padding-bottom: 50px;
          .meta_mask_connect {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 50px;
            padding-right: 20px;

          }
          .meta_wallet_data {
            display: flex;
            flex-direction: column;
            width: 100%;

            .meta_key_wrap {
              display: flex;
              flex-direction: column;
              margin-bottom: 50px;
              .wallet_key {
                margin: 0;
                color: white;
                font-size: 24px;
                font-weight: 400;
              }
            }
            .meta_currency_wrap {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              flex-wrap: wrap;
              .meta_currency {
                width: 50%;
                .input_currency {
                  width: calc(100% - 20px);
                }
              }
            }
          }
        }
      }

      .right_wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding-top: 20px;
        .collection_wrap {
          width: 100%;
          height: 100%;
          overflow: scroll;

          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
  }
}
