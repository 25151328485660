@import 'styles/variables';

.login-providers {
  position: relative;
  display: flex;
  flex-direction: column;

  > div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-of-type) {
      border-bottom: 1px solid $primaryColor;
      padding-bottom: 20px;
    }

    &:not(:first-of-type) {
      padding-top: 20px;
    }
  }
}

.login-providers .login-providers--disabled {
  filter: grayscale(1);
  cursor: default;
}

.login-providers__title {
  font-size: 24px;
  color: #ffffff;
  text-align: center;
}

.login-providers__subtitle {
  width: 100%;
  font-size: 16px;
  text-align: center;
  color: $secondaryColor;
}

.login-providers__badge {
  position: absolute;
  right: 0;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  background-color: $secondaryColor;
  color: $white;
  padding: 3px 6px;
  width: fit-content;
  border-radius: 16px;
}

.login-providers__accounts-icon {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 20px;
}

.login-providers__icon {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
}
