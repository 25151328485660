.header {
  margin: 0;
  font-size: 32px;
  color: white;
  text-transform: uppercase;
  align-self: flex-start;
  font-weight: 700;
  font-style: italic;
  @media (max-width: 1280px) {
    font-size: 26px;
  }
}

.description {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: $menuLinkColor;
  line-height: 19px;
  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 16px;
    overflow: auto;
  }
}
