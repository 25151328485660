@import 'styles/variables';
@import 'styles/breakpoints';

$landingMainGameCardWidth: 160px;
$landingMainGameCardOffset: 30px;
$landingMainGameCardOffsetMobile: 12px;

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px 0;
  text-align: center;
  margin: 0 auto;

  @include above-breakpoint('small') {
    padding: 0 0 80px 0;
  }

  @include above-breakpoint('large') {
    padding: 120px 0 80px 0;
  }
}

.main-text {
  max-width: 1070px;
}

.content {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(12, [col-start] 1fr);
  width: 100%;
  margin-top: 40px;

  @include above-breakpoint('small') {
    gap: 20px;
  }

  @include above-breakpoint('medium') {
    margin-top: 60px;
    gap: 40px;
  }
}

.card {
  min-height: 359px;
  height: 100%;
  flex-direction: row;

  @include above-breakpoint('small') {
    gap: 13px;
    min-height: 370px;
  }

  @include above-breakpoint('medium') {
    gap: 20px;
    min-height: 474px;
  }

  &__button {
    min-width: 140px;
  }

  &__background {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom right;
  }
}

.logo {
  margin-bottom: 30px;
  height: 60px;

  @include below-breakpoint('small') {
    height: 80px;
  }
}

.reality-nft-card,
.rmv-token-card,
.reality-games-card {
  > div {
    height: 100%;
  }
}

.rmv-token-card {
  grid-column: span 12;

  @include above-breakpoint('medium') {
    grid-column: span 5;
  }

  &__content {
    max-width: 390px;
  }

  &__tokens {
    position: absolute;
    z-index: 1;
    bottom: 32px;
    right: 34px;
    width: 183px;

    @include above-breakpoint('small') {
      width: initial;
    }
  }

  &__tokens-background {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    mix-blend-mode: screen;
    width: 333px;

    @include above-breakpoint('small') {
      width: initial;
    }
  }
}

.reality-nft-card {
  grid-column: span 12;

  @include above-breakpoint('medium') {
    grid-column: span 7;
  }

  &__content {
    @include above-breakpoint('small') {
      max-width: 418px;
    }

    @include above-breakpoint('medium') {
      max-width: 379px;
    }
  }

  &__nft-cards {
    position: absolute;
    bottom: 27px;
    right: 20px;
    width: 131px;
    filter: drop-shadow(0px 0 9px $extraLightBlue);

    @include above-breakpoint('small') {
      bottom: 23.5px;
      right: 33.5px;
      width: initial;
    }

    @include above-breakpoint('medium') {
      bottom: 38.5px;
      right: 38.5px;
    }
  }
}

.reality-games-card {
  grid-column: span 12;

  @include below-breakpoint('small') {
    .card {
      min-height: 447px;
    }
  }

  &__content {
    @include above-breakpoint('small') {
      max-width: 280px;
    }

    @include above-breakpoint('medium') {
      max-width: 350px;
    }
  }

  &__bokeh {
    position: absolute;
    left: calc(470px + 50%);
    transform: translateX(-400px);
    top: 60px;
  }

  &__graphics {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__content &__games {
    margin-top: 20px;

    @include above-breakpoint('small') {
      display: none;
    }
  }

  &__graphics &__games {
    @include below-breakpoint('small') {
      display: none;
    }
  }

  &__games {
    display: flex;
    max-width: 100%;
  }

  &__game {
    filter: drop-shadow(20px 0px 20px rgba(14, 38, 89, 0.5));
    width: 25%;

    img {
      max-width: 100%;
    }

    &:not(:first-child) {
      margin-left: -$landingMainGameCardOffsetMobile;
      @include above-breakpoint('mediumSmall') {
        margin-left: -$landingMainGameCardOffset;
      }
    }

    &:nth-of-type(1) {
      z-index: 5;
    }

    &:nth-of-type(2) {
      z-index: 4;
    }

    &:nth-of-type(3) {
      z-index: 3;
    }

    &:nth-of-type(4) {
      z-index: 2;
    }

    &:nth-of-type(5) {
      z-index: 1;
    }
  }
}
