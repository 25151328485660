@import 'styles/variables';

.button {
  text-decoration: underline;
  color: $green;
  text-transform: uppercase;
}

.content {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.icon {
  width: 44px;
}
