@import '../variables';

.landlord-go {
  overflow: hidden;

  @media (max-width: $screenSize1381px) {
    .glowing-flare {
      display: none;
    }
  }

  .landlord-go_content {
    position: relative;
    z-index: 0;
  }

  &_heading {
    position: relative;

    @media (max-width: $screenSize900px) {
      min-height: 500px;
    }

    .play-button-container {
      opacity: 1;
      transition: opacity;

      &.playing {
        transition: opacity;
        opacity: 0;
      }

      a {
        position: absolute;
        bottom: 20%;
        left: calc(50% - 70px);
        z-index: 2;
        background: linear-gradient(180deg, #3fe09b 0, #10a966 100%);
        border-radius: 25px;
        padding: 8px 16px;
        text-transform: uppercase;
        color: #fff !important;
        font-weight: 700;
        font-size: 0.7rem;
        text-align: center;
        font-family: Roboto;
        letter-spacing: 1px;
        text-shadow: 0 0 5px #000;
        width: 150px;
        height: 25px;
        box-shadow: 0 3px 7px 7px rgb(0 0 0 / 70%);
        display: block;

        @media (min-width: calc($screenSize900px - 1px)) {
          display: none;
        }
      }
    }

    .landlord-go_main-image img {
      z-index: 1;
      position: absolute;
      max-width: 100%;
      width: 100%;

      @media (max-width: $screenSize900px) {
        min-height: 500px;
        object-fit: cover;
      }
    }
  }

  &_stores {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 90px;
    gap: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: -5px;

    @media (min-width: $screenSize1381px) {
      bottom: -30px;
    }

    img {
      width: 107.5px;
      height: 32px;
    }
  }

  @media (min-width: $screenSize1381px) {
    &_stores img {
      width: 215px;
      height: 64px;
    }
  }

  &_sections {
    section {
      margin-top: -32px;
    }

    section:nth-child(1) {
      background-color: $primaryColor;
      z-index: 1;
      margin-top: -50px;
    }

    section:nth-child(2) {
      background-color: #143478;
      z-index: 2;
    }

    section:nth-child(3) {
      background-color: #1f408c;
      z-index: 3;
    }

    section:nth-child(4) {
      background-color: #143478;
      z-index: 4;
    }

    section:nth-child(5) {
      background-color: $primaryColor;
      z-index: 5;
    }

    section:nth-child(6) {
      background-color: #010f2f;
      z-index: 6;
    }

    section:nth-child(7) {
      background-color: $primaryColor;
      z-index: 7;
    }

    section:nth-child(8) {
      background-color: #143478;
      z-index: 8;
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px 20px 20px;
    width: 100%;
    height: 240px;
    background-color: black;

    @media ($screenSize680px) {
      padding: 0 0 45px 0;
      height: 240px;
    }

    @media (min-width: $screenSize1381px) {
      height: 300px;
      clip-path: polygon(0 32px, 100% 0, 100% 100%, 0 100%);
      margin-top: -32px;
      z-index: 9;
    }

    p {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #4987ff;
    }

    button {
      font-weight: bold;
      font-size: 14px;
      line-height: normal;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #4987ff;
      border-radius: 20px;
      border: 2px solid #4987ff;
      margin-left: 30px;
      padding: 5px 12px;
      background-color: black;

      &:hover {
        transition: 0.2s;
        cursor: pointer;
        color: white;
        border: 2px solid white;
      }
    }

    img {
      width: 91px;
      height: 27px;
    }
  }
}

.play-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  max-width: 70px;
  width: 100%;
  height: 70px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  padding-left: 5px;
  cursor: pointer;
  display: none;

  @media (min-width: $screenSize900px) {
    display: flex;
  }

  > div {
    width: 20px;
    height: 20px;
    margin: auto auto;
    background-color: white;
    clip-path: polygon(0 0, 100% 50%, 100% 50%, 0% 100%);
  }
}
