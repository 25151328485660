@import 'styles/variables';

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.typing-text {
  &::after {
    content: '';
    display: inline-block;
    width: 4px;
    height: 20px;
    margin-bottom: -2px;
  }

  &--green::after {
    background-color: $green;
  }

  &--red::after {
    background-color: $redLight;
  }

  &--animated {
    &::after {
      animation: blink 0.9s steps(2) infinite forwards;
    }
  }
}
