@import 'styles/breakpoints';
@import 'styles/variables';

.tabs {
  position: relative;
  display: flex;
  border-radius: 40px;
  background-color: $tabsBackground;
}

button.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  height: 40px;
  border-radius: 32px;
  color: $tabsColor;
  margin: 4px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  &:focus-visible {
    outline-width: 0;
  }

  &:first-child {
    margin-left: 4px;
  }

  &:last-child {
    margin-right: 4px;
  }

  &[data-headlessui-state='selected'] {
    background-color: $tabsActiveBackground;
    color: $tabsActiveColor;
  }

  &:disabled {
    &:not(:first-child) {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  @include above-breakpoint('medium') {
    height: 48px;
    font-size: 22px;
    line-height: 28px;
  }
}
