@import 'styles/variables';

.info-tooltip {
  &:hover .info-box {
    color: $light;

    .info-icon--inverse {
      opacity: 1;
    }
  }
}

.info-box {
  display: flex;
  align-items: center;
  gap: 6px;
  color: $secondaryColor;
  transition: color 0.2s ease;
}
