@use 'sass:math';
@import '../../../../styles/breakpoints';
@import 'styles/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flipAndFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(50vw) translateY(-200%) rotateY(180deg);
  }
}

.lootbox-success {
  &__rewards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    opacity: 0;
    animation: fadeIn 0.2s ease forwards 0.2s;
  }

  &__reward {
    flex-grow: 1;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.2s ease, transform 0.2s ease;

    &--fade-in {
      opacity: 1;
      transform: translateY(0);
    }

    &--fade-out {
      animation: flipAndFadeOut 1s ease 0.5s forwards;
    }
  }

  &__rewards--fade-out &__slider-container {
    overflow: visible;
  }

  &__rewards--fade-out {
    :global {
      .slick-initialized .slick-slide:not(.slick-active) {
        visibility: hidden;
      }

      .slick-list {
        overflow: visible;
      }
    }
  }

  &__slider-container {
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;

    @include above-breakpoint('small') {
      width: calc(100% - 230px);
    }
  }

  &__slider {
    margin-right: -8px;
    padding-bottom: 40px;

    @include above-breakpoint('small') {
      margin-right: -15px;
    }

    :global {
      .slick-list {
        max-height: 389px;
      }
    }
  }

  &__slider &__carousel-dots {
    position: relative;
    bottom: unset;
    top: 0;
    margin-top: 20px;
  }

  &__card {
    display: block;
  }

  &__card-wrapper {
    margin: 0 15px;

    @include below-breakpoint('small') {
      margin: 0 8px;
    }
  }
}

button.carousel-button {
  &--left {
    height: 388px;
    display: none;
    margin-left: 20px;
    transform: rotateZ(0);

    @include above-breakpoint('small') {
      left: -100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--right {
    height: 388px;
    margin-right: 20px;
    display: none;
    transform: rotateZ(-180deg);

    @include above-breakpoint('small') {
      right: -100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
