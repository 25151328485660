@use 'sass:math';
@import '../../../styles/breakpoints';
@import 'styles/variables';

.lootbox-market {
  position: relative;
  display: flex;
  gap: 40px;
  max-width: 100%;
  overflow: hidden;

  &__title {
    display: -webkit-box;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
    font-size: 32px;
    color: $light;
    margin-bottom: 12px;
    height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }

  &__slider {
    max-width: 100%;
    width: 100%;
    margin-right: -8px;
    padding-bottom: 40px;

    @include above-breakpoint('small') {
      margin-right: -15px;
    }

    :global {
      .slick-track {
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

  &__slider &__carousel-dots {
    bottom: 0px;
  }

  &__card {
    position: relative;
    z-index: 1;
    display: block;
    transform: translateZ(0);
  }

  &__card-wrapper {
    position: relative;
    margin: 0 15px;

    @include below-breakpoint('small') {
      margin: 0 8px;
    }

    &--disabled .lootbox-market__card {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__badge-soldout {
    width: 220px;
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 10;
    font-family: 'Barlow Condensed', sans-serif;
    text-shadow: 0 22.4px 22.4px rgba(0, 0, 0, 0.5);
    background: linear-gradient(315deg, #b10014, #df011a);
    cursor: default;
    border-radius: 6px;
    border-width: 2px;
    border-style: solid;
    border-color: white;
    padding: 11px;
    font-size: 24px;
    line-height: 1;
    font-style: italic;
    color: white;
    transform: rotate(-15deg) translateX(-50%) translateZ(4px);
    transform-style: preserve-3d;
  }

  &__info {
    justify-content: center;
  }

  &__purchase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  &__loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
}
