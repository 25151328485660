@use 'sass:math';
@import 'styles/variables';

$graphPointerWidth: 14px;
$graphPointerPaddingLeftRight: 70px;
$graphPointerDashSideLeftRight: 42px;
$graphPointerDashSideTop: 40px;
$graphPointerDashSideWidth: math.hypot($graphPointerDashSideLeftRight, $graphPointerDashSideTop);
$graphPointerDashOffsetLeftRight: $graphPointerPaddingLeftRight - $graphPointerDashSideWidth;

@keyframes clippath {
  0% {
    clip-path: inset(100% 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes clippath-reverse {
  0% {
    clip-path: inset(0 0 100% 100%);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

.graph-pointer {
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &--left.graph-pointer--animate::before {
    animation: clippath 0.3s 0.2s forwards linear;
  }

  &--left.graph-pointer--animate::after {
    animation: clippath 0.3s 0.5s forwards linear;
  }

  &--right.graph-pointer--animate::before {
    animation: clippath-reverse 0.3s 0.2s forwards linear;
  }

  &--right.graph-pointer--animate::after {
    animation: clippath-reverse 0.3s 0.5s forwards linear;
  }

  &.graph-pointer--animate &__circle {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: calc(100% - 70px);
    background-color: rgba($light, 0.8);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: $graphPointerDashSideWidth;
    background-color: rgba($light, 0.8);
  }

  &--left::after {
    top: 0;
    right: $graphPointerDashOffsetLeftRight;
    transform-origin: top left;
    transform: rotate(40deg);
    clip-path: inset(100% 100% 0 0);
  }

  &--right::after {
    top: 0;
    left: $graphPointerDashOffsetLeftRight;
    transform-origin: top right;
    transform: rotate(-40deg);
    clip-path: inset(0 0 100% 100%);
  }

  &--left::before {
    right: $graphPointerPaddingLeftRight;
    clip-path: inset(100% 100% 0 0);
  }

  &--right::before {
    left: $graphPointerPaddingLeftRight;
    clip-path: inset(0 0 100% 100%);
  }

  &__content {
    padding-top: 10px;
  }

  &--left &__content {
    padding-right: $graphPointerPaddingLeftRight;
  }

  &--right &__content {
    padding-left: $graphPointerPaddingLeftRight;
  }

  &__circle {
    position: absolute;
    width: $graphPointerWidth;
    height: $graphPointerWidth;
    border-radius: 50%;
    border: 3px solid $light;
    opacity: 0;
    transition: opacity 0.2s 1s ease;
  }

  &--left &__circle {
    transform: translate(100%, -50%);
    top: $graphPointerDashSideTop;
    right: $graphPointerDashSideLeftRight - $graphPointerDashOffsetLeftRight;
  }

  &--right &__circle {
    transform: translate(-100%, -50%);
    top: $graphPointerDashSideTop;
    left: $graphPointerDashSideLeftRight - $graphPointerDashOffsetLeftRight;
  }
}
