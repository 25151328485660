.slider {
  $buttonSize: 32px;
  $gapSize: 20px;
  $scrollbarSize: 10px;

  height: 100%;
  margin-right: -$scrollbarSize;

  :global {
    .swiper-button-prev,
    .swiper-button-next {
      z-index: 1;
      top: unset;
      bottom: 0;
      width: $buttonSize;
      height: $buttonSize;
      border-radius: 50%;
      border: 2px solid theme('colors.menuLinkColor');

      &:after {
        content: '';
      }

      &:hover {
        border-color: theme('colors.white');
      }
    }

    .swiper-button-prev {
      left: calc($scrollbarSize / 2);
    }

    .swiper-button-next {
      right: $scrollbarSize;
      transform: rotateY(180deg);
    }

    .swiper-button-prev,
    .swiper-button-prev:focus,
    .swiper-button-prev:hover,
    .swiper-button-next,
    .swiper-button-next :focus,
    .swiper-button-next :hover {
      background-image: url('../../../../assets/slider/slider-arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    .swiper-pagination {
      height: $buttonSize;
      width: calc(100% - 4 * $buttonSize + $scrollbarSize / 2);
      left: calc($buttonSize - $scrollbarSize / 2);
      right: $buttonSize;
      bottom: 0;
      display: flex;
      align-items: center;
      gap: calc($gapSize / 2);
      margin: 0 $buttonSize;

      .swiper-pagination-bullet {
        width: 100%;
        height: 4px;
        background-color: theme('colors.backgroundColorDark');
        border-radius: 50px;
        padding: 0;
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        background-color: theme('colors.turquoise');
      }
    }
  }
}
