@use 'sass:math';
@import 'styles/variables';

$containerPadding: 13px;
$scrollContainerPaddingTop: 5px;
$textBoxHeight: 40px;
$textBoxPadding: 5px;
$textLineHeight: math.div($textBoxHeight, 2) - $textBoxPadding;

.search_results_wrapper {
  position: relative;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 32px;
  padding: $containerPadding - $scrollContainerPaddingTop 0 $containerPadding 0;
  background-color: $primaryColor;
  height: 100%;
  max-width: 292px;

  @media (min-width: $screenSize1280px) {
    height: calc(100% + 80px);
  }

  /*
    3 Columns:
    > 1025px
    <= 1440px
  */
  @media (min-width: 1025px) {
    max-width: 430px;
  }

  /*
    4 Columns:
    > 1440px
    <= 2560px
  */
  @media (min-width: 1441px) {
    max-width: 567px;
  }

  /*
    5 Columns:
    > 2560px
  */
  @media (min-width: 2561px) {
    max-width: 703px;
  }

  @media (max-height: $screenSize500px) {
    position: absolute;
    left: 254px;
    top: 80px;
    width: 435px;
  }

  .empty_search {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    color: $thirdColor;
  }

  .search_results_header {
    font-size: 16px;
    margin: 0 0 8px;
    color: $menuLinkBgHoverColor;
  }

  .search_results_content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 127px);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-radius: 12px;
    padding: $scrollContainerPaddingTop + 2px $containerPadding 0 $containerPadding;
  }
}

.search_results_inner_wrapper {
  position: relative;
  width: 100%;
  border-radius: 25px;
}

.search_results_element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 16px;
  padding: 4px;
  width: 128px;
  height: 164px;
  cursor: pointer;
  background-color: $pageDefaultBg;
  background-image: linear-gradient(0deg, $pageDefaultBg 14%, rgba(31, 64, 140, 0) 30%);
  background-size: contain;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 0 1.5px #4df9ff, 0 0 0 #4df9ff, 0 0 0 #4df9ff, 0 0 0rem #4df9ff, 0 0 15px #4df9ff;
  }
}

.search_element_title_wrap {
  width: 100%;
  height: $textBoxHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: $textBoxPadding;
  background-color: $cardBackgroundDark;
  border-radius: 12px;
}

.search_element_title {
  position: relative;
  display: -webkit-box;
  max-width: 100%;
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: $textLineHeight;
  color: white;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
}

.search_results_wrapper .active {
  -webkit-box-shadow: 0 0 14px 0 rgba(73, 135, 255, 1);
  -moz-box-shadow: 0 0 14px 0 rgba(73, 135, 255, 1);
  box-shadow: 0 0 14px 0 rgba(73, 135, 255, 1);
}

.search_search_icon {
  @media (max-width: 1280px), (max-height: 800px) {
    height: 12px;
    width: 12px;
  }
}
