@import 'styles/variables';
@import '../../../styles/breakpoints';

.account-dropdown {
  width: 100vw;
  min-height: 100%;
  max-width: 100%;
  background: $cardBackgroundDark;
  box-shadow: 0 4px 16px 0 rgba($black, 0.3);
  border: 2px solid $pageDefaultBg;
  border-radius: 8px;

  @include above-breakpoint('extraSmall') {
    min-height: auto;
    width: 280px;
  }
}

.dropdown-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-item {
  & &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: 10px 14px;
    color: $light;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 14px;
    color: $secondaryColor;
  }
}
