@import 'styles/variables';

.modal-wrong-network {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  p {
    text-align: center;
    font-size: 16px;
    color: $white;
  }
}
