.text_and_value_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.value_and_icon {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.value_and_icon_value {
  margin: 0 0 0 5px;
  font-size: 14px;
  color: $white;
  font-weight: 400;
}

.text_and_value_description {
  font-size: 14px;
  color: $menuLinkColor;
  font-weight: 400;
  margin-right: 10px;
}

.value_and_icon_icon {
  height: 16px;
  width: 16px;
  margin-right: 2px;
}

.horizontal_line {
  height: 1px;
  background-color: $menuLinkColor;
  margin: 5px 0;
}
