@import 'styles/variables';
@import '../../../styles/breakpoints';

.progress-modal {
  margin: 10px 0;

  &__text {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  &__content {
    width: 260px;
    height: 260px;
    max-width: 100%;

    @include above-breakpoint('small') {
      width: 360px;
      height: 360px;
    }
  }

  &__lootbox > video {
    position: relative;
    width: 100%;
    padding-bottom: 0;
  }
}

.main-content-hidden {
  position: absolute;
  z-index: -1;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
