@use 'sass:math';
@import 'styles/variables';
@import '../../styles/breakpoints';

.page-wrapper {
  position: relative;
  z-index: 0;
  min-height: 100%;
  width: 1200px;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;

  &:not(.page-wrapper--without-footer) {
    padding-bottom: $footerHeightMobile;

    @include above-breakpoint('small') {
      padding-bottom: $footerHeightMedium;
    }

    @include above-breakpoint('mediumSmall') {
      padding-bottom: $footerHeight;
    }
  }

  &--full-page {
    width: 100%;
  }

  &--minimized {
    padding: 20px 0;
    overflow: visible;

    &:not(.page-wrapper--without-footer) {
      padding: 20px 0 $footerHeightMobile + 20px 0;

      @include above-breakpoint('small') {
        padding: 50px 0 $footerHeightMedium + 20px 0;
      }

      @include above-breakpoint('mediumSmall') {
        padding: 50px 0 $footerHeight + 20px 0;
      }
    }

    @include above-breakpoint('small') {
      border-radius: $pageRounding $pageRounding 0 0;
      padding: 50px 0 20px 0;
    }
  }
}

.footer {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
}
