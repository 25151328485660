@import 'styles/variables';

.loading-spinner {
  &__spinner {
    stroke-dasharray: 90 140;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    animation: spinnerAnimation 1s ease infinite;
  }

  &__background {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: $primaryColor;
    stroke-width: 4;
  }

  &__gradient {
    color: $green;
  }
}

@keyframes spinnerAnimation {
  from {
    stroke-dashoffset: -30;
  }
  to {
    stroke-dashoffset: 200;
  }
}
