@import 'styles/variables';
@import '../../../styles/breakpoints';

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $cardBackgroundDark;
  height: $menuMobileHeight;
  padding: 0 20px;
  box-shadow: 0 0 20px 0 rgba($black, 0.3);

  @include above-breakpoint('extraSmall') {
    height: $menuDesktopHeight;
  }
}

.menu__logo {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 2px solid $pageDefaultBg;

  @include above-breakpoint('mediumLarge') {
    padding-right: 30px;
    margin-right: 30px;
  }

  img {
    height: 48px;
  }
}

.nav {
  position: absolute;
  z-index: 100;
  left: 0;
  top: $menuMobileHeight;
  height: calc(var(--vh, 1vh) * 100 - $menuMobileHeight);
  box-shadow: inset 0 20px 20px -20px rgba($black, 0.3);
  width: 304px;
  padding: 20px;
  transform: translateX(-100%);
  transition: transform 0.2s ease;
  background: $cardBackgroundDark;

  @include above-breakpoint('extraSmall') {
    top: $menuDesktopHeight;
    height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
  }

  &--expanded {
    transform: translateX(0);
  }

  &__backdrop {
    position: fixed;
    z-index: 99;
    top: $menuMobileHeight;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 - $menuMobileHeight);
    background: #040e23;
    opacity: 0.7;
    transition: visibility 0.2s ease, opacity 0.2s ease;

    @include above-breakpoint('extraSmall') {
      top: $menuDesktopHeight;
      height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
    }
  }
}
