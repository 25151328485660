@import 'styles/variables';

.nft-card {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background-color: $pageDefaultBg;

  &__background {
    z-index: 0;
    width: 100%;
    padding-bottom: 100%;
  }

  &__caption {
    min-height: 32px;
  }

  &__shares {
    align-self: center;
  }

  &__name-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
  }

  &__name-normal,
  &__name-small {
    display: -webkit-box;
    padding: 0 2px 4px;
    color: $light;
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__name-normal {
    font-size: 20px;
    line-height: 16px;
  }

  &__name-small {
    font-size: 18px;
    line-height: 14px;
  }

  &__address {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 600;
  }

  &__marker {
    width: 70px;
    height: 70px;
    margin: 5px;
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 8px 8px 8px;
    margin-top: 15px;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -13px;
      left: 50%;
      display: block;
      transform: translate(-50%, -100%);
      width: calc(100% + 16px);
      height: 24px;
      background-image: url('../../../assets/nft-card/nft-card-image-background.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  &__marker-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -100%);
    width: 80px;
    height: 80px;
    background-image: url('../../../assets/nft-card/nft-card-market-background.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
