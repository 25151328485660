.venue-background-container {
  position: relative;
}

.venue-background-video {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-position: top;
}

.venue-background-container--cover {
  .venue-background-video {
    object-fit: cover;
  }
}

.venue-background-container--contain {
  .venue-background-video {
    object-fit: contain;
  }
}

.video-button {
  position: absolute;
  z-index: 1;
  bottom: 25px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: rgb(31, 64, 140, 0.55);
  backdrop-filter: blur(2px);

  svg {
    animation: fadeIn;
    animation-duration: 0.55s;
  }
}

.video-button-mini {
  width: 35px;
  height: 35px;
  font-size: 20px;
  bottom: 8px;
  left: 3px;

  svg {
    width: 8.5px;
    height: 11px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
