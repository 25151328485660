@import 'styles/variables';

.form-field-error {
  display: none;
  color: $redLight;
  margin-top: 4px;

  &.show {
    display: block;
  }
}
