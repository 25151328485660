@import 'styles/variables';

.consents-modal {
  z-index: 101;
}

.consents-modal-container {
  width: 512px;
  max-width: 100%;
}

.consents-paragraph {
  margin: 44px 0;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.consents-form {
  color: $light;

  &__content {
    margin-bottom: 44px;
  }
}

.consents-link {
  color: $green;
  text-decoration: underline;
}

.consents-checkbox {
  font-size: 16px;
  line-height: 19px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.consents-submit {
  width: 100%;
  text-transform: uppercase;
}
