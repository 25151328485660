@import 'styles/variables';
@import 'styles/breakpoints';

.game-card {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  color: $light;
  text-align: start;
  transition: transform 0.3s ease;

  @include above-breakpoint('small') {
    max-width: 330px;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    height: 100%;
    width: 100%;
    padding: 30px;
    background: linear-gradient(180deg, rgba(5, 24, 65, 0) 31.71%, rgba(5, 24, 65, 0.4) 100%);
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__title {
    position: absolute;
    left: 30px;
    bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    transition: opacity 0.3s ease;
  }

  &__description {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  &__description-title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
  }

  &__image {
    position: relative;
    z-index: 1;
    max-width: 100%;
    transition: opacity 0.3s ease;
  }

  &:hover &__image {
    opacity: 0.4;
  }

  &:hover {
    transform: translateY(-10px);
  }

  &:hover &__details {
    opacity: 1;
  }

  &:hover &__title {
    opacity: 0;
  }
}
