@import 'styles/variables';
@import 'styles/breakpoints';

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $cardBackgroundDark;
  height: $menuDesktopHeight;
  padding: 0 40px;
  box-shadow: 0 0 20px 0 rgba($black, 0.3);
}

.menu__logo {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 2px solid $pageDefaultBg;

  @include above-breakpoint('navMedium') {
    padding-right: 30px;
    margin-right: 30px;
  }

  img {
    height: 48px;
  }
}

.nav {
  margin-right: auto;
  flex-grow: 1;
  max-width: 1180px;
}

.nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0;
  list-style: none;
}
