@use 'sass:math';
@import '../../../styles/breakpoints';

$ecosystemGraphicWidth: 708px;
$ecosystemGraphicHeight: 617px;

.section {
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: repeat(12, [col-start] 1fr);
  padding: 50px 0;
  margin: 0 auto;

  @include above-breakpoint('small') {
    padding: 80px 0;
  }

  @include above-breakpoint('medium') {
    padding: 120px 0 80px 0;
  }

  .paragraph {
    padding-bottom: 20px;
  }

  .ecosystem {
    position: relative;
    grid-column: span 12;
    padding-top: math.div($ecosystemGraphicHeight, $ecosystemGraphicWidth) * 100%;

    @include below-breakpoint('medium') {
      order: 2;
    }

    @include above-breakpoint('medium') {
      grid-column: span 7;
    }

    > div {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .summary {
    grid-column: span 12;

    @include above-breakpoint('medium') {
      grid-column: span 5;
    }
  }
}
