@import '../../../styles/breakpoints';

.menu-wallet-connect-control {
  min-width: 22px;
}

.connect-button {
  display: flex;
  justify-content: center;
  padding: 10px;
  white-space: nowrap;
  text-transform: uppercase;

  @include above-breakpoint('large') {
    padding: 12px 32px;
  }

  @include below-breakpoint('large') {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  .icon {
    @include above-breakpoint('large') {
      display: none;
    }
  }

  .text {
    display: none;

    @include above-breakpoint('large') {
      display: block;
    }
  }
}
