@import '../../../styles/variables';
@import '../../../styles/breakpoints';

$tab-translate: 320px;

button.tab {
  text-transform: none;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: unset;
  font-size: 18px;
  padding: 4px 8px;

  @include above-breakpoint('medium') {
    font-size: 24px;
  }

  &[data-headlessui-state='selected'] {
    background-color: #1f408c;
  }

  &:first-child {
    margin-left: 8px;
  }

  &:last-child {
    margin-right: 8px;
  }
}

.tab-panel {
  position: relative;
  display: block !important;

  > div {
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    z-index: 0;
  }

  &[data-headlessui-state='selected'] {
    z-index: 1;
  }

  &[data-headlessui-state='selected'] > div {
    opacity: 1;
    transform: translateX(0);
  }

  &:not([data-headlessui-state='selected']) {
    position: absolute;
    z-index: -1;
  }

  &:not([data-headlessui-state='selected']) > div {
    opacity: 0;
    transform: translateX(-100%);
  }
}
