@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.card {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: inherit;
    border: 2px solid $light;
    mask-image: linear-gradient(to bottom right, black 30%, transparent 100%);
    opacity: 0.1;
  }
}
