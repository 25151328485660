@import 'styles/variables';

.search-bar {
  position: relative;

  &__clear-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $secondaryColor;
    font-size: $inputFontSize;
    padding: 0;
    border: none;
    outline: none;
    line-height: $inputLineHeight;
    font-size: $inputFontSize;
    background-color: transparent;
    cursor: pointer;

    & > img {
      margin-top: 5px;
      margin-right: 16px;
      width: 15px;
    }
  }

  &--outlined &__input {
    border: 1px solid $secondaryColor;
  }

  &__input {
    width: 100%;
    height: $inputHeight;
    padding: $inputPadding 30px $inputPadding $inputPadding;
    border-radius: $inputRadius;
    background-color: $primaryColor;
    border: 0;
    outline: 0;
    color: $inputColor;

    &::placeholder {
      color: $inputPlaceholderColor;
    }

    &:focus {
      border: 1px solid $secondaryColor;
    }
  }
}
