@import '../../../styles/breakpoints';

$nftCardDesktopScale: 1.75;

.progress-modal-content {
  width: 641px;
  max-width: 100%;
}

.success-modal-content {
  width: 641px;
  max-width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include below-breakpoint('extraSmall') {
    background-color: transparent;
  }

  @include above-breakpoint('extraSmall') {
    padding: 30px 40px;
    margin-bottom: 30px;
  }

  h1 {
    margin-bottom: 24px;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;

    @include above-breakpoint('extraSmall') {
      font-size: 32px;
    }
  }
}

.info {
  font-size: 16px;
  line-height: 24px;
}

.nft-card-wrapper {
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 370px) {
    width: 271px;
  }

  @include above-breakpoint('extraSmall') {
    width: 271px * $nftCardDesktopScale;
    height: 351px * $nftCardDesktopScale;
    max-height: 351px * $nftCardDesktopScale;
  }
}

.nft-card {
  flex-basis: 0;
  width: 100%;
  max-width: 100%;
  height: 351px;
  max-height: 351px;
  margin: 0;
  transform-origin: top left;
  cursor: default;

  @media (min-width: 370px) {
    width: 271px;
    max-width: 271px;
  }

  @include above-breakpoint('extraSmall') {
    transform: scale($nftCardDesktopScale);
  }
}
