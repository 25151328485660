.input_regular {
  background-color: $primaryColor;
  color: $inputColor;
  height: $inputHeight;
  font-size: 16px;
  padding: 7px 16px;
  border-radius: 16px;
  padding: $inputPadding;
  border-radius: $inputRadius;
  border: none;

  &::placeholder {
    color: $inputPlaceholderColor;
  }

  &:focus {
    border: 1px solid $secondaryColor;
  }
}
