@import '../../../../styles/variables';

.lootbox-counter {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $primaryColor;
  color: $light;
  border-radius: 18px;

  &--small {
    padding: 4px 10px;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }

    .lootbox-counter__icon {
      height: 12px;
    }
  }

  &--medium {
    padding: 6px 20px;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 21px;
    }

    .lootbox-counter__icon {
      height: 20px;
    }
  }
}
