@import 'styles/variables';

.card {
  border-radius: $cardBorderRadius;
  padding: $cardPadding;
  background-color: $cardBackgroundColor;
  color: $cardColor;

  &--dark {
    background-color: $backgroundColorDark;
  }

  &--medium {
    background-color: $cardBackgroundDark;
  }
}
