.scrollbar {
  overflow-y: auto;
  box-sizing: content-box;
  scrollbar-color: theme('colors.backgroundColorLight') theme('colors.backgroundColorDark');
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  padding-left: 4px;
  padding-right: 7px;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  background: theme('colors.backgroundColorDark');
  border-radius: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: theme('colors.backgroundColorLight');
  border-radius: 6px;
}
