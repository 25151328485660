@import './variables';
@import './breakpoints';

body {
  font-family: $mainFontFamily;
  font-style: normal;
  overflow: hidden;
  background-color: $primaryColor;
}

* {
  scrollbar-color: $backgroundColorDark transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba($backgroundColorDark, 0.5);
  border-radius: 3px;
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 6px;
}

a {
  text-decoration: none;
}

canvas {
  display: block;
}

.app {
  height: calc(var(--vh, 1vh) * 100);
}

.map-route-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  width: 100%;
  background: #005ce8;
}

.mapboxgl-control-container {
  display: none !important;
}

.hided {
  opacity: 0 !important;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(31, 64, 140, 0.75);

  @media (min-width: $screenSize680px) and (min-height: $screenSize680px) {
    border-radius: $pageRounding;
  }
}

.relative {
  position: relative;
}

.expand-hoover {
  z-index: 10;
  transition: all 0.25s ease-in;

  &:hover {
    transform: scale(1.1);
  }
}

.grecaptcha-badge {
  z-index: 10;
}

.rc-slider-mark-text {
  cursor: default !important;
}

#mava-webchat-launcher {
  bottom: 70px !important;

  @include above-breakpoint('small') {
    bottom: 100px !important;
  }
}

#mava-iframe-container {
  @include above-breakpoint('xxs') {
    bottom: 140px !important;
  }

  @include above-breakpoint('small') {
    bottom: 170px !important;
  }
}
