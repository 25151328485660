@import 'styles/variables';

.tooltip {
  &-content {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: -20px;
    padding: 15px 20px 20px 20px;
    background-color: $primaryColor;
    color: $light;
    border-radius: 16px;
    visibility: hidden;
    transform: translateX(-50%) translateY(-100%);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    opacity: 0;
    transition: opacity 0.2s ease;

    &::after {
      content: '';
      position: absolute;
      z-index: 3;
      left: 50%;
      transform: translateX(-50%) translateY(100%);
      bottom: 1px;
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 14px solid $primaryColor;
      border-bottom-left-radius: 7px;
    }

    a {
      text-decoration: underline;
      color: $secondaryColor;
    }

    &--bottom {
      width: max-content;
      transform: translateX(-50%) translateY(0);
      top: 34px;

      &::after {
        transform: translateX(-50%) translateY(-100%) rotateX(180deg);
        bottom: unset;
        top: 1px;
      }
    }

    &--left {
      width: max-content;
      left: -14px;
      transform: translateX(-100%) translateY(-50%);
      top: 50%;

      &::after {
        bottom: unset;
        top: 50%;
        left: unset;
        right: -20px;
        transform: translateY(-50%) rotateZ(-90deg);
      }
    }

    &--right {
      width: max-content;
      right: -14px;
      left: unset;
      transform: translateX(100%) translateY(-50%);
      top: 50%;

      &::after {
        bottom: unset;
        top: 50%;
        left: unset;
        left: -20px;
        transform: translateY(-50%) rotateZ(90deg);
      }
    }
  }

  &:hover &-content {
    visibility: visible;
    opacity: 1;
  }
}
