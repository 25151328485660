@import 'styles/variables';
@import '../../../styles/breakpoints';

.section {
  padding-top: 25px;
  padding-bottom: 50px;

  @include above-breakpoint('small') {
    padding: 40px 0 80px 0;
  }

  @include above-breakpoint('medium') {
    padding: 60px 0 120px 0;
  }
}

.card-wrapper {
  max-width: 1090px;
  margin: 0 auto;
}

.card {
  &::before {
    opacity: 1;
    border: 2px solid #4195d2;
    mask-image: linear-gradient(
      to bottom right,
      transparent 16.5%,
      black 33%,
      transparent 49.5%,
      black 66%,
      transparent 100%
    );
  }

  &__content {
    min-height: 300px;

    @include above-breakpoint('small') {
      max-width: 317px;
    }

    @include above-breakpoint('medium') {
      max-width: 513px;
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom right;
  }

  &__cards-image {
    position: absolute;
    right: 25px;
    bottom: -47px;
    width: 186px;

    @include above-breakpoint('small') {
      right: 36px;
      bottom: -86px;
      width: 315px;
    }

    @include above-breakpoint('medium') {
      right: 80px;
      bottom: -109px;
      width: initial;
    }
  }
}
