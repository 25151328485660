@import 'styles/variables';
@import '../../../styles/breakpoints';

.purchase-view {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  &__main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  &__main-text {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    text-transform: uppercase;
  }

  &__transaction {
    font-family: 'IBM Plex Mono';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #f83a56;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @include above-breakpoint('small') {
      gap: 20px;
    }
  }

  &__button {
    width: 172px;
  }
}
