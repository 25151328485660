$mainFontFamily: 'Rajdhani', sans-serif;
$machineTypeStyleFontFamily: 'Courier New';

//colors
$white: #fff;
$light: #fff;
$black: #000;
$primaryColor: #0e2659;
$secondaryColor: #4987ff;
$thirdColor: #fff;
$textsBackgroundColor: #143478;
$green: #00eb8a;
$greenLight: #01e48a;
$greenDark: #00ab64;
$orangeLight: #ffd600;
$orangeDark: #f77901;
$redLight: #f83a56;
$redDark: #c1243b;
$lightGray: #afbaca;
$gray: #cbcfd8;
$extraLightBlue: #6dfbff;
$darkGray: #707d94;
$backgroundColorDark: #0e2659;
$footerBackgroundColor: #0a1d46;
$blue: #4fa3ed;
$lightBlue: #00c4ff;
$turquoise: #6dfbff;

//menu
$menuBgHoverColor: #143478;
$menuLinkColor: #4987ff;
$menuLinkBgHoverColor: #005ce8;

//pages
$pageDefaultBg: #1f408c;
$backButtonHover: #143478;

//cards
$cardBorderRadius: 16px;
$cardPadding: 20px;
$cardBackgroundColor: $pageDefaultBg;
$cardColor: $white;

// badges
$badgeBlueBackground: $menuLinkBgHoverColor;
$badgeBlueOutlineColor: $menuLinkColor;
$badgeBlueOutline: $menuLinkColor;
$badgeDarkBackground: #16284b;

//effects
$cornerRounding: 16px;
$pageRounding: 32px;
$pageFooterBackgroundColor: #000430;

//card
$cardInfoTextColor: #143478;
$cardBackgroundDark: #143478;
$cardTopInformationColor: #1f408c;

//form
$inputRadius: 20px;
$inputBackgroundColor: #182d5a;
$inputPlaceholderColor: #1f408c;
$inputRadius: 8px;
$inputFontSize: 16px;
$inputPadding: 14px;
$inputHeight: 48px;
$inputLineHeight: $inputHeight - $inputPadding * 2;
$inputBackgroundColor: #0e2659;
$inputDisabledBackgroundColor: rgba($inputBackgroundColor, 0.5);
$inputDisabledColor: $secondaryColor;
$inputPlaceholderColor: $secondaryColor;
$inputColor: $light;
$inputLabelColor: $light;
$inputSecondaryBackgroundColor: #1f408c;
$inputSecondaryColor: $light;
$buttonDisabledColor: $lightGray;

// landlord go
$landLordGoColorSecondary: #6dfbff;
$landLordGoBackgroundColorMain: #000758;
$landLordGoBackgroundTrendsCard: #13368f;
$landLordGoBackgroundMapCard: #0c1a64;
$landLordGoCitySectionBackground: #0b268c;
$landLordGoBuildingsSectionBackground: #12379a;

// about
$aboutColorSecondary: #6dfbff;
$aboutMainSectionBackgroundColor: #0f2f98;
$aboutRoyaltiesSectionLightningColor: #00ffa3;
$aboutVideosCollectionSectionBackgroundColor: #0f2f98;
$aboutCreatorsPartnersSectionBackgroundColor: #0f2f98;
$aboutSocialMediaActiveColor: #07e88d;
$aboutPartnersActiveColor: #3fffb1;
$aboutCreatorCardBackgroundColor: #000430;
$aboutCreatorCardStroke: #25c4f4;

// menu
$menuDesktopHeight: 76px;
$menuMobileHeight: 62px;
$menuMobileLandingHeight: 54px;
$freeNFTBanner: 40px;
$freeNFTBannerMobile: 62px;
$footerHeight: 88px;
$footerHeightMedium: 120px;
$footerHeightMobile: 160px;

// tabs
$tabsBackground: #0e2659;
$tabsColor: #4987ff;
$tabsActiveBackground: #005ce8;
$tabsActiveColor: #ffffff;

//buttons
$buttonColorConfig: (
  default: (
    color: $white,
    background: transparent,
    border: 1px solid $white,
    cursor: pointer,
  ),
  primary: (
    color: $white,
    background: linear-gradient(180deg, $greenLight 0%, $greenDark 100%),
    border: 0,
    cursor: pointer,
  ),
  warning: (
    color: $white,
    background: linear-gradient(180deg, $orangeLight 0%, $orangeDark 100%),
    border: 0,
    cursor: pointer,
  ),
  accent: (
    color: $white,
    background: linear-gradient(180deg, $redLight 0%, $redDark 100%),
    border: 0,
    cursor: pointer,
  ),
  blue: (
    color: $white,
    background: linear-gradient(180deg, #00e0ff 0%, $menuLinkBgHoverColor 100%),
    border: 0,
    cursor: pointer,
  ),
  disabled: (
    color: $white,
    background: $lightGray,
    border: 0,
    cursor: default,
  ),
  green: (
    color: $primaryColor,
    background: $green,
    border: 2px solid $green,
    cursor: pointer,
  ),
  green-outline: (
    color: $green,
    background: transparent,
    border: 2px solid $green,
    cursor: pointer,
  ),
  white-outline: (
    color: $white,
    background: transparent,
    border: 2px solid $white,
    cursor: pointer,
  ),
  disabled-outline: (
    color: $pageDefaultBg,
    background: $backgroundColorDark,
    border: 2px solid $backgroundColorDark,
    cursor: default,
  ),
);
$buttonSizeConfig: (
  small: (
    padding: 6.5px,
    height: 32px,
    line-height: 19px,
    font-size: 16px,
    font-weight: 700,
    border-radius: 16px,
    border-width: 1px,
    padding-mobile: 6.5px,
    height-mobile: 32px,
    line-height-mobile: 19px,
    font-size-mobile: 16px,
    font-weight-mobile: 700,
    border-radius-mobile: 16px,
    border-width-mobile: 1px,
  ),
  medium-small: (
    padding: 11px 16px,
    height: 42px,
    line-height: 16px,
    font-size: 20px,
    font-weight: 700,
    border-radius: 50px,
    border-width: 2px,
    padding-mobile: 6px,
    height-mobile: 42px,
    line-height-mobile: 16px,
    font-size-mobile: 20px,
    font-weight-mobile: 700,
    border-radius-mobile: 50px,
    border-width-mobile: 2px,
  ),
  medium: (
    padding: 12px 24px,
    height: 48px,
    line-height: 26px,
    font-size: 20px,
    font-weight: 700,
    border-radius: 24px,
    border-width: 2px,
    padding-mobile: 12px,
    height-mobile: 48px,
    line-height-mobile: 26px,
    font-size-mobile: 20px,
    font-weight-mobile: 700,
    border-radius-mobile: 24px,
    border-width-mobile: 2px,
  ),
  medium-large: (
    padding: 13px,
    height: 54px,
    line-height: 28px,
    font-size: 24px,
    font-weight: 700,
    border-radius: 27px,
    border-width: 2px,
    padding-mobile: 7px,
    height-mobile: 42px,
    line-height-mobile: 24px,
    font-size-mobile: 20px,
    font-weight-mobile: 700,
    border-radius-mobile: 24px,
    border-width-mobile: 2px,
  ),
  regular: (
    padding: 18px,
    height: 64px,
    line-height: 28px,
    font-size: 24px,
    font-weight: 700,
    border-radius: 32px,
    border-width: 2px,
    padding-mobile: 12px,
    height-mobile: 48px,
    line-height-mobile: 24px,
    font-size-mobile: 20px,
    font-weight-mobile: 700,
    border-radius-mobile: 24px,
    border-width-mobile: 2px,
  ),
);

$subPageTitleShadowColor: #86fbff;

//media query
// TODO: replace with new breakpoints mixins
$screenSize680px: 680px;
$screenSize787px: 787px;
$screenSize800px: 800px;
$screenSize880px: 880px;
$screenSize900px: 900px;
$screenSize1080px: 1080px;
$screenSize1150px: 1150px;
$screenSize1280px: 1280px;
$screenSize1380px: 1380px;
$screenSize1381px: 1381px;
$screenSize1459px: 1459px;
$screenSize1658px: 1658px;
$screenSize1850px: 1850px;
$screenSize2116px: 2115px;
$screenSize2850px: 2850px;

// MAP uniqe breakpoints
$screenSize500px: 500px;
$screenSize880px: 880px;
$screenSize1500px: 1500px;
$screenSize1600px: 1600px;
