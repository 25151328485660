@import '../../../styles/breakpoints';

$graphicsOriginalHeight: 478px;
$graphicsMobileScale: 0.75;

$gameImageWidth: 456px;
$gameImageHeight: 446px;
$percentageLeftWidth: 248px;
$percentageLeftHeight: 504px;
$nftCardWidth: 357px;
$nftCardHeight: 480px;
$percentageRightWidth: 192px;
$percentageRightHeight: 504px;
$tradingRoyaltiesWidth: 357px;
$tradingRoyaltiesHeight: 454px;

@keyframes floatLeft {
  from {
    transform: translateX(2px);
  }
  65% {
    transform: translateX(0px);
  }
  to {
    transform: translateX(2px);
  }
}

@keyframes floatRight {
  from {
    transform: translateX(-2px);
  }
  65% {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-2px);
  }
}

.content-wrapper {
  max-width: 100%;
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 30px;

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
}

.stores {
  position: absolute;
  right: 32px;
  bottom: 20px;
  display: flex;
  gap: 14px;
  width: 340px;
  transform: translateY(100%);

  &__download {
    flex-grow: 1;
    flex-basis: 0;

    img {
      width: 100%;
    }
  }
}

.trading-royalties {
  margin-left: 20px;
}

.slide-scroll-icon {
  margin-top: 40px;

  path:first-child {
    animation: floatLeft 2s ease infinite;
  }

  path:last-child {
    animation: floatRight 2s ease infinite;
  }
}

.game-stores {
  width: $gameImageWidth * $graphicsMobileScale;
  height: $gameImageHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $gameImageWidth;
    height: $gameImageHeight;
  }
}

.percentage-left {
  width: $percentageLeftWidth * $graphicsMobileScale;
  height: $percentageLeftHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $percentageLeftWidth;
    height: $percentageLeftHeight;
  }
}

.nft-card {
  width: $nftCardWidth * $graphicsMobileScale;
  height: $nftCardHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $nftCardWidth;
    height: $nftCardHeight;
  }
}

.percentage-right {
  width: $percentageRightWidth * $graphicsMobileScale;
  height: $percentageRightHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $percentageRightWidth;
    height: $percentageRightHeight;
  }
}

.trading-royalties {
  width: $tradingRoyaltiesWidth * $graphicsMobileScale;
  height: $tradingRoyaltiesHeight * $graphicsMobileScale;

  @include above-breakpoint('medium') {
    width: $tradingRoyaltiesWidth;
    height: $tradingRoyaltiesHeight;
  }
}
