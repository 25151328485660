@import 'styles/variables';
@import 'styles/breakpoints';

$mainPageContainerPadding: 20px;

.footer {
  min-height: $footerHeightMobile;
  background-color: #0e2659;
  padding-top: 60px;
  padding-bottom: 40px;

  @include below-breakpoint('mediumSmall') {
    padding-bottom: 30px;
  }
  @include below-breakpoint('extraSmall') {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  &__container {
    max-width: 1280px + 2 * $mainPageContainerPadding;
    padding-left: $mainPageContainerPadding;
    padding-right: $mainPageContainerPadding;
    margin-left: auto;
    margin-right: auto;
  }

  &__content {
    @include below-breakpoint('small') {
      text-align: center;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 2px solid #1f408c;

    @include below-breakpoint('mediumSmall') {
      padding-bottom: 30px;
    }

    @include below-breakpoint('small') {
      display: block;
      padding-bottom: 0;
    }

    img {
      @include below-breakpoint('mediumSmall') {
        width: 174px;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 30px;

    @include above-breakpoint('mediumLarge') {
      padding-bottom: 0;
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__copyright {
    font-size: 18px;
    color: #527cd0;

    @include below-breakpoint('mediumSmall') {
      font-size: 14px;
    }

    @include below-breakpoint('small') {
      justify-content: center;
    }
  }
}

.nav-list {
  display: flex;
  gap: 20px;
  list-style: none;
  color: $light;
  padding: 0;
  margin-bottom: 0;
  @include below-breakpoint('mediumSmall') {
    padding-right: 0;
  }
  @include below-breakpoint('small') {
    justify-content: center;
  }
  @include below-breakpoint('extraSmall') {
    gap: 10px;
  }

  @include between-breakpoints('small', 'mediumLarge') {
    margin-right: 60px;
  }
}

.nav-list__item a {
  font-size: 18px;
  color: #527cd0;
  text-decoration-line: underline;

  @include below-breakpoint('mediumSmall') {
    font-size: 14px;
  }
}

.nav-bar_social__list {
  display: flex;
  align-items: center;
  padding: 0;
  list-style: none;
  gap: 20px;

  @include between-breakpoints('small', 'large') {
    margin-right: 60px;
  }

  @include below-breakpoint('small') {
    justify-content: center;
    margin: 34px 0;
  }

  .nav-bar__social___icon {
    svg {
      fill: $blue;
      height: 26px;
      width: 26px;

      &:hover {
        fill: $extraLightBlue;
      }
    }
  }
}
