@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/Rajdhani/Rajdhani-Medium.ttf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/Rajdhani/Rajdhani-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/Rajdhani/Rajdhani-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../assets/fonts/Barlow/BarlowCondensed-ExtraBold.ttf');
  font-weight: 800;
  font-display: swap;
}
