@use 'sass:map';

[data-testid='token-select'] {
  span {
    color: $light;
  }
}

[data-testid='action-button'] > button,
button[data-testid='connect-wallet'] {
  background: $green;
  color: $light;
  border-radius: 16px;
  text-transform: uppercase;

  &:enabled:hover {
    background: $green;
  }

  > div {
    color: $light;
  }
}
