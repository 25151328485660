@use 'sass:map';
@import '../breakpoints';
@import '../variables';

@mixin getButtonColorConfig($colorConfigValue) {
  color: map.get($colorConfigValue, 'color');
  background: map.get($colorConfigValue, 'background');
  border: map.get($colorConfigValue, 'border');
  text-align: center;
  cursor: map.get($colorConfigValue, 'cursor');
}

@mixin getButtonSizeConfig($sizeConfigValue) {
  padding: map.get($sizeConfigValue, 'padding');
  height: map.get($sizeConfigValue, 'height');
  line-height: map.get($sizeConfigValue, 'line-height');
  font-size: map.get($sizeConfigValue, 'font-size');
  font-weight: map.get($sizeConfigValue, 'font-weight');
  border-radius: map.get($sizeConfigValue, 'border-radius');
  border-width: map.get($sizeConfigValue, 'border-width');
  text-transform: uppercase;

  @include below-breakpoint('extraSmall') {
    padding: map.get($sizeConfigValue, 'padding-mobile');
    height: map.get($sizeConfigValue, 'height-mobile');
    line-height: map.get($sizeConfigValue, 'line-height-mobile');
    font-size: map.get($sizeConfigValue, 'font-size-mobile');
    font-weight: map.get($sizeConfigValue, 'font-weight-mobile');
    border-radius: map.get($sizeConfigValue, 'border-radius-mobile');
    border-width: map.get($sizeConfigValue, 'border-width-mobile');
  }
}
