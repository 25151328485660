@import 'styles/breakpoints';

.button {
  width: 147px;
  min-width: 128px;
  margin: 0 20px;
  white-space: nowrap;

  @include above-breakpoint('navMedium') {
    width: 132px;
    margin-left: 10px;
  }
}
