@use 'sass:map';
@import '../../../styles/breakpoints';
@import 'styles/variables';
@import '../../../styles/animations/shine-animation';

@keyframes nftFadeAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.account-control {
  position: relative;
  display: flex;
  align-items: center;
  height: map.get($buttonSizeConfig, 'medium', 'height');
  border-radius: map.get($buttonSizeConfig, 'medium', 'border-radius');

  &__new-nfts {
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: map.get($buttonSizeConfig, 'medium', 'border-radius');

    span {
      opacity: 0;
      font-weight: 700;
      color: $light;
      font-size: 40px;
      -webkit-text-stroke: 2px $aboutColorSecondary;
      text-shadow: -4px 4px 0px #0f2f98, -8px 7px 0px rgba(109, 251, 255, 0.5), -4px 4px 2px rgba(15, 35, 83, 0.25);
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }

    &::after {
      position: absolute;
      z-index: 3;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
      transform: skewX(-25deg);
    }
  }

  &--new-nfts &__new-nfts::after {
    animation: shine 1.5s forwards;
  }

  &--new-nfts &__new-nfts span {
    animation: nftFadeAnimation 1s 0.2s forwards;
  }

  @include above-breakpoint('large') {
    width: 187px;
    padding: 5px 15px 5px 5px;
    background-color: $pageDefaultBg;
  }

  &__dropdown {
    position: absolute;
    z-index: 10;
    right: -20px;
    bottom: -5px;
    overflow-y: overlay;
    transform-origin: top right;
    transform: translateY(100%) scale(0, 0);
    height: calc(var(--vh, 1vh) * 100 - $menuMobileHeight);
    opacity: 0;
    transition: transform 0.4s ease, opacity 0.3s ease;

    @include above-breakpoint('extraSmall') {
      height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
    }

    &--visible {
      transform: translateY(100%) scale(1, 1);
      opacity: 1;
    }

    @include above-breakpoint('extraSmall') {
      right: 0;
    }
  }

  &__backdrop {
    position: fixed;
    z-index: 9;
    top: $menuMobileHeight;
    left: 0;
    width: 100vw;

    @include above-breakpoint('extraSmall') {
      top: $menuDesktopHeight;
    }
  }
}

.avatar {
  height: 38px;
  width: 38px;
  min-width: 38px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.avatar,
.user-info {
  margin-right: 6px;
}

.user-info {
  @include below-breakpoint('large') {
    display: none;
  }
}

.user-name {
  font-weight: 500;
  font-size: 16px;
  color: $light;
}

.user-address {
  font-size: 14px;
  color: $light;
}

.collapse-button {
  margin-left: auto;
}

.profile-link {
  display: flex;
  align-items: center;
}

.collapse-icon {
  width: 14px;
  height: 8px;
  color: $secondaryColor;
  transform: rotate(180deg);
  transition: transform 0.4s ease;

  &--visible {
    transform: rotate(0deg);
  }
}
