@import '../../../../styles/variables';
@import '../../../../styles/breakpoints';

.shares-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
  padding: 6px 25px;
  color: $light;
  font-size: 20px;
  border-radius: 8px;
  line-height: 1;
  text-align: center;
  background: $primaryColor;

  @include above-breakpoint('small') {
    font-size: 24px;
    height: 44px;
  }
}

.shares-icon {
  width: 18.7px;

  @include above-breakpoint('small') {
    width: 21px;
  }
}
