@import 'styles/variables';
@import '../../styles/breakpoints';

.page-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: $white;
  margin: 20px 0;
  padding: 0 15px;

  @include above-breakpoint('small') {
    font-size: 48px;
  }

  @include above-breakpoint('mediumLarge') {
    padding: 0;
  }
}
