.lootboxes {
  .lootbox_content {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    position: relative;
    .bg{
      height: 100%;
      width: 100%;
      background: url("../../assets/coming_soon/img_lootboxes.png") no-repeat top center;
      background-size: 100% auto ;
    }
    .text {
      position: absolute;
      top: 45%;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 128px;
      font-style: italic;
      color: white;
      font-weight: 700;
      transform-origin: 0 0;
      transform: rotate(-4deg);
      text-shadow: 0 0 16px #09CEFF;
      margin: 0;
      @media (max-width: 1380px) {
        font-size: 80px;
      }
    }
  }
}
