.form-field {
  position: relative;
  padding-bottom: 20px;

  input:disabled {
    color: $inputPlaceholderColor;
  }

  .error-message {
    position: absolute;
    bottom: 0;
    margin-top: 5px;
    margin-bottom: 0;
    color: $redLight;
  }

  &__label {
    position: relative;
    display: block;

    p {
      margin-bottom: 0;
      color: $secondaryColor;
      font-weight: 700;
    }

    input {
      height: 48px;
      height: $inputHeight;
      width: 100%;
      margin-top: 10px;
      font-size: 24px;
    }
  }

  &__prefix + input {
    padding-left: 50px;
  }

  &__suffix + input {
    padding-right: 50px;
  }

  &__suffix,
  &__prefix {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 28px;
    width: 28px;
    color: $white;
  }

  &__suffix {
    bottom: 10px;
    right: 10px;
    height: 28px;
    width: 28px;
  }

  &__prefix {
    top: 10px;
    left: 10px;
  }
}
