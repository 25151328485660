@import 'styles/variables';
@import '../../../../styles/breakpoints';

.account-badge {
  padding: 13px 14px;
  background-color: $pageDefaultBg;

  @include above-breakpoint('large') {
    display: none;
  }
}

.balance-section {
  position: relative;
  padding: 18px 14px;

  &__caption {
    text-align: center;
    color: $secondaryColor;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 10px 0;
    }

    li:not(:last-child) {
      border-bottom: 1px solid $backgroundColorDark;
    }
  }

  .buy-rmv-control {
    width: 100%;
    margin: 0;
    text-transform: uppercase;
  }
}
