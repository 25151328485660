@import '../../../../styles/breakpoints';
@import '../../../../styles/variables';

.wrapper {
  background: #0e2659;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 16px;

  @include above-breakpoint('extraSmall') {
    padding: 24px 40px;
    margin-bottom: 20px;
  }

  @include above-breakpoint('medium') {
    padding: 35px 40px;
  }

  p:not(:last-child),
  p:nth-last-child(n + 2) {
    padding-bottom: 20px;
  }
}

button.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: $light;
  width: 100%;
  text-align: start;

  @include above-breakpoint('extraSmall') {
    font-size: 20px;
    line-height: 28px;
  }

  @include above-breakpoint('small') {
    font-size: 26px;
    line-height: 28px;
  }

  @include above-breakpoint('medium') {
    font-size: 30px;
    line-height: 28px;
  }

  span {
    transition: color 0.2s ease;
  }

  svg {
    width: 40px;
    min-width: 40px;
    color: $secondaryColor;
    transition: transform 0.2s ease, color 0.2s ease;
  }

  &--open {
    color: #6dfbff;

    svg {
      color: #143478;
      transform: rotate(-45deg);
    }
  }
}

.content {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $white;
  padding-top: 15px;

  @include above-breakpoint('extraSmall') {
    font-size: 20px;
    line-height: 28px;
  }

  @include above-breakpoint('small') {
    font-size: 20px;
    line-height: 28px;
    padding-top: 26px;
  }
}
