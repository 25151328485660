@import 'styles/variables';

.modal-progress-header {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  width: min(500px, 80vw);
  height: 50px;
  text-align: center;
}

.modal-progress-content {
  h1 {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $white;
  }

  p {
    text-align: center;
    line-height: 19px;
    color: $secondaryColor;
  }
}
