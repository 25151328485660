@use 'sass:map';
@import '../../../../styles/breakpoints';
@import 'styles/variables';

$avatarSize: 38px;

.account-badge {
  display: flex;
  align-items: center;
}

.avatar {
  height: $avatarSize;
  width: $avatarSize;
  min-width: $avatarSize;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid $light;
  }
}

.avatar,
.user-info {
  margin-right: 6px;
}

.user-info {
  width: calc(100% - $avatarSize - 6px);
}

.user-name {
  font-weight: 500;
  font-size: 16px;
  color: $light;
}

.user-address {
  font-size: 14px;
  color: $secondaryColor;

  &__container {
    display: flex;
    justify-content: space-between;
  }
}

.address-button {
  color: $green;
  text-decoration: underline;
}

.collapse-button {
  margin-left: auto;
}
