@import 'styles/variables';
@import 'styles/breakpoints';

.modal {
  width: 638px;
  max-width: 100vw;

  @include below-breakpoint('small') {
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }

  &__title {
    font-size: 20px;
    //padding: 10px calc(50% - 80px);

    @include above-breakpoint('small') {
      width: auto;
    }
  }

  &__title > button {
    align-items: flex-start;
    padding-top: 10px;
  }
}

.modal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: $light;

  &__text {
    margin-bottom: 10px;
  }

  &__balance {
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }

  &__balance-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: $secondaryColor;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin-top: 30px;

    @include above-breakpoint('small') {
      margin-top: 28px;
      gap: 20px;
    }
  }

  &__footer-button {
    flex-grow: 1;
    flex-basis: 200px;

    @include below-breakpoint('small') {
      &:first-child {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
      }
    }
  }
}
