@use 'sass:map';
@import '../../styles/mixins/button-config';
@import 'styles/variables';

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__check-mark {
    position: relative;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border: 2px solid $greenLight;
    border-radius: 6px;
    margin-right: 8px;
    transition: background-color 0.2s ease;
    user-select: none;
  }

  &__check-mark-overlay {
    position: absolute;
    z-index: 1;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.2s ease;
    }
  }

  &--checked {
    .checkbox__check-mark {
      border-color: transparent;
    }

    .checkbox__check-mark-overlay {
      color: map.get(map.get($buttonColorConfig, 'primary'), 'color');
      background: map.get(map.get($buttonColorConfig, 'primary'), 'background');

      img {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  input:focus-visible + &__check-mark {
    box-shadow: 0px 0px 1px 1px $greenLight;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
