@use 'sass:map';
@import '../../styles/breakpoints';
@import 'styles/variables';

.badge-round {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
  font-size: 12px;
  font-weight: 700;
  color: map.get(map.get($buttonColorConfig, 'accent'), 'color');
  background: map.get(map.get($buttonColorConfig, 'accent'), 'background');
  border-radius: 50%;

  @include above-breakpoint('small') {
    font-size: 18px;
    height: 28px;
    min-height: 28px;
    width: 28px;
    min-width: 28px;
  }
}
