@import 'styles/variables';

.nft-card {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  height: 100%;

  &:hover {
    .nft-card__name-normal,
    .nft-card__name-small {
      color: $turquoise;
    }
  }

  &__background {
    z-index: 2;
    width: 100%;
    padding-bottom: 100%;
  }

  &__content {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 3;
      top: 4px;
      left: 50%;
      display: block;
      width: calc(100% + 10px);
      height: 14px;
      transform: translate(-50%, -100%);
      background-image: url('../../../assets/nft-card/nft-card-image-background.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  &__name-normal,
  &__name-small {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    padding: 7px 6px;
    color: $light;
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: color 0.3s ease;
  }

  &__name-normal {
    font-size: 16px;
    line-height: 14px;
    height: 42px;
  }

  &__name-small {
    font-size: 14px;
    line-height: 12px;
    height: 30px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #1f408c;
    background-color: $pageDefaultBg;
    background-image: linear-gradient(rgba(14, 38, 89, 0) 79.88%, $pageDefaultBg 100%);
  }
}
