@import '../../../styles/breakpoints';
@import 'styles/variables';

.purchase-view {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  &__main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  &__comment {
    max-width: 406px;
    padding: 10px 12px;
    border: 1px solid #deff58;
    border-radius: 10px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #deff58;

    @include above-breakpoint('small') {
      font-size: 15px;
      line-height: 20px;
    }
  }

  &__main-text {
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;

    @include above-breakpoint('small') {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__transaction {
    font-family: 'IBM Plex Mono';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: $green;

    @include above-breakpoint('small') {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @include below-breakpoint('small') {
      width: 100%;
    }
  }

  &__button {
    padding-left: 32px;
    padding-right: 32px;

    @include below-breakpoint('small') {
      width: 100%;
    }
  }
}
