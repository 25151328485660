@use 'sass:map';

$breakpoints: (
  xxs: 400px,
  extraSmall: 576px,
  small: 768px,
  mediumSmall: 992px,
  tw-lg: 1024px,
  medium: 1170px,
  mediumLarge: 1360px,
  large: 1500px,
  extraLarge: 1920px,
  navMedium: 1650px,
  2k: 2560px,
  4k: 3840px,
);

@mixin above-breakpoint($breakpointName) {
  @media (min-width: map.get($breakpoints, $breakpointName)) {
    @content;
  }
}

@mixin below-breakpoint($breakpointName) {
  @media (max-width: #{map.get($breakpoints, $breakpointName) - 1px}) {
    @content;
  }
}

@mixin between-breakpoints($lower, $upper) {
  @media (min-width: map.get($breakpoints, $lower)) and (max-width: #{map.get($breakpoints, $upper) - 1px}) {
    @content;
  }
}
