@import 'styles/variables';

.modal-progress-header {
  &__step {
    z-index: 2;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: $primaryColor;

    &--active {
      animation: activated 0.75s forwards;
    }
  }

  &__line {
    height: 2px;
    width: 50px;
    background-color: $primaryColor;
    border-radius: 100px;
    flex: 1;

    &--active {
      animation: activated-line 1.25s forwards;
    }

    &:last-child {
      display: none;
    }
  }
}

@keyframes activated {
  0% {
    background-color: $primaryColor;
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    background-color: $green;
    transform: scale(1);
  }
}

@keyframes activated-line {
  0% {
    background-color: $primaryColor;
  }

  100% {
    background-color: $green;
    transform: scale(1);
  }
}
