@import 'styles/variables';
@import 'styles/breakpoints';

.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;

  .menu--mobile {
    @include above-breakpoint('navMedium') {
      display: none;
    }
  }

  .menu--regular {
    @include below-breakpoint('navMedium') {
      display: none;
    }
  }
}
