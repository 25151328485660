@import 'styles/variables';
@import '../../styles/breakpoints';

.full-screen-loading-spinner {
  position: fixed;
  z-index: 9;
  background-color: rgba($primaryColor, 0.9);
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - $menuMobileHeight);
  animation: fadeIn 0.2s;

  @include above-breakpoint('extraSmall') {
    height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
  }

  &--opaque {
    background-color: $primaryColor;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
