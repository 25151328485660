@import 'styles/variables';

.sale-counter-wrapper {
  width: 480px;
  position: absolute;
  height: 135px;
  bottom: 70px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screenSize680px) {
    left: 70px;
    bottom: 32px;
  }

  .counter-label {
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }

  .counter {
    display: flex;
    gap: 20px;
    align-items: center;
    align-content: center;
    flex: 1 1 auto;
    border-radius: 50px;
    padding: 20px 60px;
    text-align: center;
    color: white;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 40%);
    background-color: $primaryColor;
    height: 100px;

    .single-date-part {
      flex-grow: 1;
      width: 25%;

      .value {
        font-size: 52px;
        font-weight: bold;
        color: white;
        line-height: 50px;
      }

      .label {
        font-size: 16px;
        color: #4987ff;
        line-height: 14px;
      }
    }
  }
}

.sale-counter-wrapper-map {
  position: fixed;
  height: 135px;
  bottom: 80px;
  transform: scale(0.8);
  transform-origin: center bottom;
  left: calc(50% - 240px);

  @media (min-width: 1400px) and (max-width: 1616px) {
    left: calc(50% - 154px);
  }

  @media (min-width: 1617px) {
    left: calc(50% - 240px);
  }
}
