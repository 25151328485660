@import 'styles/variables';
@import 'styles/breakpoints';

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $cardBackgroundDark;
  height: $menuDesktopHeight;
  padding: 0 40px;
  box-shadow: 0 0 20px 0 rgba($black, 0.1);
}

.menu__logo {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 2px solid $pageDefaultBg;

  @include above-breakpoint('mediumLarge') {
    padding-right: 30px;
    margin-right: 30px;
  }

  img {
    height: 34px;
  }
}

.nav {
  margin-right: auto;
}

.nav-list {
  display: flex;
  align-items: center;
  padding: 0;
  list-style: none;

  li:first-child {
    a {
      display: flex;
      align-items: center;
    }
  }

  li {
    padding-right: 20px;
    text-transform: uppercase;
    min-height: 100%;
    align-self: stretch;

    &__text {
      color: $white;
    }

    @include above-breakpoint('extraLarge') {
      padding-right: 30px;
    }

    @include above-breakpoint('2k') {
      padding-right: 40px;
    }
  }
}

.nav-text {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 33px;
  color: $white;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;

  &__staking {
    color: $green;
  }

  img {
    margin-right: 10px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 2px;
    width: 100%;
    background-color: $extraLightBlue;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover,
  &.nav-text--active {
    color: $extraLightBlue;

    &::after {
      opacity: 1;
    }
  }
}

.nav-bar_social__list {
  display: flex;
  align-items: center;
  padding: 0;
  list-style: none;

  .nav-bar__social___icon {
    padding-right: 28px;
    padding-right: 20px;

    @include above-breakpoint('extraLarge') {
      padding-right: 28px;
    }

    svg {
      fill: $blue;
      transition: fill 0.3s ease;
      height: 24px;
      width: 24px;

      &:hover {
        fill: $extraLightBlue;
      }
    }
  }
}

.banner-button {
  &__text {
    padding-left: 24px;
    padding-right: 24px;
    line-height: 26px;
  }
}
