@import 'styles/variables';

.category-id-section {
  position: absolute;
  z-index: 1;
  width: calc(100% - 16px);
  left: 8px;
  top: 10px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  transform: translateZ(1px);

  span {
    background-color: rgba(20, 52, 120, 0.8);
    color: $white;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 100px;

    &:first-of-type::first-letter {
      text-transform: capitalize;
    }
  }
}
