@import '../variables';

.slick-dots li button:before {
  font-size: 12px;
  height: 12px;
  width: 12px;
}

.slick-dots li.slick-active button:before {
  color: $aboutColorSecondary;
}

.slick-dots--rect.slick-dots li {
  width: 40px;
}

.slick-dots--rect.slick-dots li button:before {
  height: 4px;
  width: 40px;
  margin-top: 16px;
  border-radius: 50px;
  font-size: 12px;
  color: transparent;
  background-color: $cardBackgroundDark;
}

.slick-dots--rect.slick-dots li.slick-active button:before {
  background-color: $aboutColorSecondary;
}
