@import 'styles/variables';

.redeem-progress-modal {
  text-align: center;

  &__details {
    margin: 38px 0;

    p {
      color: $white;
      margin: 0;
    }

    &-section:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__spinner {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  &__hash-link {
    text-decoration: underline;
    color: $green;
  }

  .modal__title {
    text-transform: uppercase;
  }

  .market-offer-overview {
    margin: 0 auto;
  }

  .market-offer-overview__title {
    line-height: 1.15;
    color: $white;
  }

  .market-offer-overview__data-label {
    line-height: 1;
  }
}
