@import '../../../styles/breakpoints';
@import 'styles/variables';

.section {
  display: grid;
  align-items: stretch;
  gap: 15px;
  grid-template-columns: repeat(12, [col-start] 1fr);
  padding: 30px 0 50px 0;
  margin: 0 auto;

  @include above-breakpoint('small') {
    gap: 20px;
    padding: 40px 0 80px 0;
  }

  @include above-breakpoint('medium') {
    gap: 40px;
    padding: 80px 0 120px 0;
  }
}

.audited-by,
.token-utility {
  grid-column: span 12;

  @include above-breakpoint('medium') {
    grid-column: span 6;
  }

  & > div,
  .card {
    height: 100%;
  }
}

.audited-by {
  &__content {
    max-width: 403px;

    @include below-breakpoint('small') {
      min-height: 235px;
    }
  }

  &__shield {
    position: absolute;
    bottom: 27.5px;
    right: 28.5px;
    height: 130px;

    @include above-breakpoint('small') {
      right: 78px;
      bottom: 43px;
      height: initial;
    }

    @include above-breakpoint('medium') {
      bottom: 47px;
      right: 51px;
    }
  }
}

.token-utility {
  &__title {
    font-weight: 600;

    span {
      color: $green;
    }
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__content {
    max-width: 433px;

    @include below-breakpoint('small') {
      min-height: 450px;
    }
  }

  &__list-item {
    margin-bottom: 10px;
    line-height: 24px;

    @include above-breakpoint('small') {
      margin-bottom: 14px;
      line-height: 28px;
    }

    @include above-breakpoint('medium') {
      line-height: 30px;
    }

    @include above-breakpoint('medium') {
      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3) {
        padding-right: 130px;
      }
    }
  }

  &__coin {
    position: absolute;
    bottom: 17px;
    right: 22px;
    height: 130px;

    @include above-breakpoint('small') {
      right: 50px;
      bottom: 40px;
      height: initial;
    }

    @include above-breakpoint('medium') {
      bottom: 28px;
      right: 36px;
    }
  }
}

.card {
  &__background {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom right;
  }
}
