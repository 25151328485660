@import 'styles/variables';
@import '../../../styles/breakpoints';

.modal {
  @include below-breakpoint('extraSmall') {
    align-items: flex-end;
  }

  &__content {
    padding: 30px 20px;

    @include below-breakpoint('extraSmall') {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      min-width: 100%;
    }

    @include above-breakpoint('extraSmall') {
      padding: 20px;
    }
  }

  &__title {
    display: none;
  }
}

.purchase-method {
  position: relative;
  display: flex;
  flex-direction: column;

  &__button {
    position: relative;
    cursor: pointer;
    display: flex;

    @include above-breakpoint('extraSmall') {
      align-items: center;
      flex-direction: column;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $primaryColor;
      padding-bottom: 20px;
    }

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }
}

.purchase-method .purchase-method--disabled {
  filter: grayscale(1);
  cursor: default;
}

.purchase-method__title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 2px;
  color: $light;
  text-align: left;

  @include above-breakpoint('extraSmall') {
    text-align: center;
  }
}

.purchase-method__subtitle {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $secondaryColor;
}

.purchase-method__badge {
  margin-top: 8px;

  @include above-breakpoint('extraSmall') {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

.purchase-method__accounts-icon {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 20px;
}

.purchase-method__icon {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
  margin-right: 14px;

  @include above-breakpoint('extraSmall') {
    margin-right: 0;
  }
}
