@use 'sass:math';
@import '../../../styles/breakpoints';
@import 'styles/variables';

.lootbox-owned {
  &__title {
    display: -webkit-box;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
    font-size: 32px;
    color: $light;
    margin-bottom: 12px;
    height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }

  &__button {
    width: 100%;
  }

  &__info {
    justify-content: center;
  }

  &__purchase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
}
