@import '../../../styles/breakpoints';

.ramp-modal {
  padding: 20px 10px;
  max-width: 100vw;
  max-height: calc(var(--vh, 1vh) * 100);

  &__content {
    min-width: 320px;
    padding: 15px 10px 20px 10px;
    max-height: calc(var(--vh, 1vh) * 100);

    @include above-breakpoint('small') {
      padding: 15px 20px 20px;
      max-width: 100%;
      width: 760px;
    }
  }
}
