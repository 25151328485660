@import '../../../styles/breakpoints';
@import 'styles/variables';

$mainPageContainerPadding: 20px;

.section {
  padding-top: 50px;
  padding-bottom: 25px;

  @include above-breakpoint('small') {
    padding: 40px 0;
    padding-left: $mainPageContainerPadding;
    padding-right: $mainPageContainerPadding;
    margin-left: auto;
    margin-right: auto;
  }

  @include above-breakpoint('medium') {
    padding: 120px 0 100px 0;
  }
}

.title {
  text-align: center;
}

.tabs-wrapper {
  margin: 30px auto 60px auto;
  overflow-x: auto;
  overflow-y: hidden;

  @include above-breakpoint('small') {
    margin: 40px auto 80px auto;
  }

  @include above-breakpoint('medium') {
    margin: 60px auto 120px auto;
  }
}

.tabs {
  display: flex;
  gap: 16px;

  @include above-breakpoint('medium') {
    justify-content: center;
  }

  > button {
    white-space: nowrap;
  }
}

.graph-wrapper {
  transition: transform 0.3s ease, opacity 0.3s ease;
  height: 160px;

  @include above-breakpoint('small') {
    height: 260px;
  }

  @include above-breakpoint('medium') {
    height: auto;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 900px;
    min-width: 900px;
    transform: scale(0.4);
    transform-origin: top center;

    @include above-breakpoint('small') {
      transform: scale(0.7);
    }

    @include above-breakpoint('medium') {
      transform: scale(1);
    }
  }

  &--hidden {
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 0;
    transform: translateX(-300px);
  }

  &--visible {
    position: relative;
    opacity: 1;
    z-index: 1;
    transform: translateX(0);
  }
}

.graph {
  margin: 0 auto;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    display: block;
    width: 373px;
    height: 222px;
    border-radius: 16px;
    transform: translateX(-50%);
    background: rgba(37, 196, 244, 0.8);
    filter: blur(102.5px);

    @include above-breakpoint('medium') {
      bottom: 20px;
      top: auto;
    }
  }

  img {
    position: relative;
    z-index: 1;
  }

  &__pointer-title {
    color: $light;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  &__pointer-value {
    display: flex;
    justify-content: flex-end;
    color: $landLordGoColorSecondary;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
  }
}

.token-distribution-graph {
  position: relative;

  &__graph {
    position: relative;
    z-index: 1;
  }

  & &__pointer-1 {
    position: absolute;
    right: 585px;
    top: 204px;
    z-index: 2;

    .graph__pointer-title {
      text-align: right;
    }
  }

  & &__pointer-2 {
    position: absolute;
    right: 580px;
    top: 80px;
    z-index: 2;

    .graph__pointer-title {
      text-align: right;
    }
  }

  & &__pointer-3 {
    position: absolute;
    bottom: 295px;
    left: 510px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-end;
    }
  }

  & &__pointer-4 {
    position: absolute;
    bottom: 185px;
    left: 600px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }
}

.use-of-proceeds-graph {
  &__graph {
    position: relative;
    z-index: 1;
  }

  & &__pointer-1 {
    position: absolute;
    right: 490px;
    bottom: 320px;
    z-index: 2;

    .graph__pointer-title {
      text-align: right;
    }
  }

  & &__pointer-2 {
    position: absolute;
    right: 610px;
    bottom: 230px;
    z-index: 2;

    .graph__pointer-title {
      text-align: right;
    }
  }

  & &__pointer-3 {
    position: absolute;
    bottom: 50px;
    right: 595px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }

  & &__pointer-4 {
    position: absolute;
    bottom: 270px;
    left: 550px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }

  & &__pointer-5 {
    position: absolute;
    bottom: 170px;
    left: 605px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }
}

.company-treasure-graph {
  & &__pointer-1 {
    position: absolute;
    right: 633px;
    bottom: 310px;
    z-index: 2;

    .graph__pointer-title {
      text-align: right;
    }
  }

  & &__pointer-2 {
    position: absolute;
    right: 620px;
    bottom: 198px;
    z-index: 2;

    .graph__pointer-title {
      text-align: right;
    }
  }

  & &__pointer-3 {
    position: absolute;
    left: 430px;
    bottom: 195px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }

  & &__pointer-4 {
    position: absolute;
    left: 630px;
    bottom: 120px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }
}

.token-sale-graph {
  & &__pointer-1 {
    position: absolute;
    right: 481px;
    bottom: 301px;
    z-index: 2;
  }

  & &__pointer-2 {
    position: absolute;
    left: 485px;
    bottom: 225px;
    z-index: 2;
  }

  & &__pointer-3 {
    position: absolute;
    left: 542px;
    bottom: 133px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }

  & &__pointer-4 {
    position: absolute;
    left: 531px;
    bottom: 26px;
    z-index: 2;

    .graph__pointer-value {
      justify-content: flex-start;
    }
  }
}
