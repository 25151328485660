@import 'styles/variables';

.button {
  cursor: pointer;
  padding: 18px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: none;
  min-width: 144px;

  p {
    margin: 0;
    font-size: 24px;
    color: $white;
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase;

    @media (max-width: 1280px) {
      font-size: 18px;
    }
  }

  @media (max-width: 1280px) {
    min-width: 120px;
    padding: 8px 15px;
  }
}

.component.button-default {
  background: transparent;
  border: 2px solid $white;
}

.component.button_regular {
  min-width: 144px;
  background: linear-gradient(180deg, $greenLight 0%, $greenDark 100%);

  &:disabled {
    background: $buttonDisabledColor;
  }
}

.component.button_regular_smaller {
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: none;
  min-width: 80px;
  background: linear-gradient(180deg, $greenLight 0%, $greenDark 100%);

  @media (max-width: 1280px) {
    min-width: 120px;
    padding: 8px 15px;
  }
  .button_regular_mini_text {
    margin: 0;
    font-size: 14px;
    color: white;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;

    @media (max-width: 1280px) {
      font-size: 18px;
    }
  }

  &:disabled {
    background: $buttonDisabledColor;
  }
}

.component.back_button {
  position: absolute;
  z-index: 10;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  width: 40px;
  background: $primaryColor;
  cursor: pointer;
  height: inherit;

  @media (min-width: $screenSize680px) {
    width: 64px;
  }

  @media (min-width: $screenSize680px) and (min-height: $screenSize680px) {
    border-radius: $pageRounding 0 0 $pageRounding;
  }

  &:hover {
    background: $backButtonHover;
  }
}

// custom classes passed via classNames input
button.component {
  &.button-negative {
    background: linear-gradient(180deg, $orangeLight 0%, $orangeDark 100%) !important;
  }

  &.button-red {
    background: linear-gradient(180deg, #f83a56 0%, #c1243b 100%) !important;
  }

  &.button-full-width {
    width: 100%;
  }
}

.button--styleless {
  background-color: transparent;
  border: 0;
  outline-width: 0;
  padding: 0;
  cursor: pointer;
  appearance: none;

  &:focus {
    outline-width: 0;
  }

  &:focus-visible {
    outline-width: 1px;
  }
}
