.card {
  $scale: 1.01;
  $front: 0deg;
  $back: 180deg;

  position: relative;
  transform-style: preserve-3d;
  transform-origin: center;

  &:hover {
    transform: translateY(-2px) rotateY($front);
  }

  &--toggled {
    transform: rotateY($back);

    &:hover {
      transform: translateY(-2px) rotateY($back);
    }
  }

  @keyframes zoomEffect {
    0% {
      transform: rotateY($front);
    }
    25% {
      transform: rotateY($front) scale($scale);
    }
    75% {
      transform: rotateY($back) scale($scale);
    }
    100% {
      transform: rotateY($back);
    }
  }

  @keyframes zoomEffectReverse {
    0% {
      transform: rotateY($back);
    }
    25% {
      transform: rotateY($back) scale($scale);
    }
    75% {
      transform: rotateY($front) scale($scale);
    }
    100% {
      transform: rotateY($front);
    }
  }

  &--animating {
    animation: zoomEffect 1s forwards;
  }

  &--animating-reverse {
    animation: zoomEffectReverse 1s forwards;
  }
}
