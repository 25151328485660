@import 'styles/variables';
@import '../../../styles/breakpoints';

.menu__link {
  width: 134px;
  height: 42px;
  display: flex;
  align-items: center;
  background-image: url('../../../assets/RealityMetaverse.svg');
  background-repeat: no-repeat;
  background-position: 32px center;
  border: 2px solid #1f408c;
  border-radius: 100px;
  padding: 14px;

  svg {
    fill: $menuLinkColor;
  }

  &:hover {
    border-color: #6dfbff;

    svg {
      fill: #6dfbff;
    }
  }

  @include below-breakpoint('large') {
    margin: 0 auto;
  }
}
