@import '../variables';

.youtube-video {
  overflow: hidden;
  position: relative;
  height: calc(39.6vw - 91px);

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
