.lootbox-background {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-position: top;
  border-radius: 16px 16px 0 0;
  object-fit: contain;

  &__play-button {
    position: absolute;
    bottom: 25px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 58px;
    height: 58px;
    backdrop-filter: blur;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: rgb(31, 64, 140, 0.55);
    backdrop-filter: blur(2px);

    svg {
      animation: fadeIn;
      animation-duration: 0.55s;
    }
  }
}
