@import '../../styles/mixins/button-config';
@import 'styles/variables';

@each $colorConfigName, $colorConfigValue in $buttonColorConfig {
  .button--#{$colorConfigName} {
    @include getButtonColorConfig($colorConfigValue);
  }
}

@each $sizeConfigName, $sizeConfigValue in $buttonSizeConfig {
  .button--#{$sizeConfigName} {
    @include getButtonSizeConfig($sizeConfigValue);
  }
}

.link {
  display: contents;
}

.button {
  min-width: 100px;
}

.button-content__wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.button-content {
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: inline-block;
}

.button-content-copy {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  transform: translateY(90%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: inline-block;
}

.button:not(:disabled):not(.button--disabled):hover {
  .button-content-copy {
    transform: translateY(0);
    opacity: 1;
  }

  .button-content {
    transform: translateY(-90%);
    opacity: 0;
  }
}
