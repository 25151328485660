@import 'styles/variables';

.badge {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  background-color: $secondaryColor;
  color: $white;
  padding: 3px 8px;
  width: fit-content;
  border-radius: 16px;
}
