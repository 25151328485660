.lootbox-buy-controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__section {
    flex-grow: 1;
    flex-basis: 0;
    min-width: unset;
  }

  &__summary {
    display: flex;
    gap: 20px;
  }

  &__summary-item {
    flex-grow: 1;
    flex-basis: 0;
  }

  &__price {
    justify-content: flex-end;
  }
}
