/*The base layer is for things like reset rules or default styles applied to plain HTML elements.*/
/* @tailwind base; */
/*The components layer is for class-based styles that you want to be able to override with utilities.*/
@tailwind components;

@layer components {
  .offer-label {
    @apply tw-flex tw-items-center tw-gap-1 tw-text-xl;
  }

  .sold-out-badge {
    font-family: 'Barlow Condensed', sans-serif;
    text-shadow: 0 1.4px 1.4px #00000080;
    background: linear-gradient(315deg, #b10014, #df011a);

    @apply tw-cursor-default tw-rounded-lg tw-border-2 tw-border-solid tw-border-white tw-p-3 tw-pr-4 tw-text-2xl tw-italic tw-text-white;
  }

  button.responsive-tab {
    @apply lg:tw-h-12 lg:tw-text-[22px] lg:tw-leading-7;
  }
}

/*The utilities layer is for small, single-purpose classes that should always take precedence over any other styles.*/
@tailwind utilities;

@layer utilities {
  /*tailwind ul, ol, menu reset*/
  .list-reset {
    @apply tw-m-0 tw-list-none tw-p-0;
  }

  /*tailwind button reset*/
  .button-reset {
    @apply tw-transform-none tw-cursor-pointer tw-appearance-none tw-bg-transparent tw-p-0;
  }

  /*button style less*/
  .button-reset {
    border: 0;
    outline-width: 0;
    appearance: none;
  }

  .button-reset:focus {
    outline-width: 0;
  }

  .button-reset:focus-visible {
    outline-width: 1px;
  }

  .backface-hidden {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  .centered {
    left: 50%;
    transform: translateX(-50%);
  }
}
