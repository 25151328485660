@import 'styles/variables';

.landing-nft {
  font-family: 'Rajdhani', sans-serif;

  &__price {
    margin: 18px 0;
    justify-content: center;
  }

  &__button {
    width: 100%;
  }
}
