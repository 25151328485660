@import 'styles/variables';

.input-form-field {
  font-size: $inputFontSize;

  &--primary &__input {
    color: theme('colors.white');
    background-color: theme('colors.inputBackground');

    &:disabled {
      color: theme('colors.white50');
    }
  }

  &--secondary &__input {
    color: theme('colors.white');
    background-color: theme('colors.backgroundColorLight');

    &:disabled {
      color: theme('colors.white50');
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__label {
    display: block;
    margin-bottom: 10px;
    color: theme('colors.white');

    &--hidden {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -100;
    }
  }

  &__input {
    width: 100%;
    height: $inputHeight;
    padding: $inputPadding;
    border-radius: $inputRadius;
    background-color: theme('colors.inputBackground');
    color: theme('colors.white');
    border: 1px solid transparent;
    outline: 0;

    &::placeholder {
      color: theme('colors.menuLinkColor');
    }

    &:focus-visible {
      border: 1px solid theme('colors.menuLinkColor');
    }
  }
}
