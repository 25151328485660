.element-loader {
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.35);
  animation-name: fadeOut;
  animation-duration: 0.55s;
  display: none;

  &.disable-ui {
    isolation: isolate;
    display: inline;
    position: absolute;
    inset: 0;
    animation-name: fadeIn;
    animation-duration: 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}
