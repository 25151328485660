@import 'styles/variables';
@import 'styles/breakpoints';

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $cardBackgroundDark;
  height: $menuMobileLandingHeight;
  padding: 0 40px;
  box-shadow: 0 0 20px 0 rgba($black, 0.1);

  @include above-breakpoint('extraSmall') {
    height: $menuDesktopHeight;
  }
}

.menu__logo {
  padding-right: 15px;
  margin-right: 15px;

  @include above-breakpoint('mediumLarge') {
    padding-right: 30px;
    margin-right: 30px;
  }

  img {
    height: 26px;
  }
  @include above-breakpoint('extraSmall') {
    img {
      height: 34px;
    }
  }
}

.nav {
  position: absolute;
  z-index: 100;
  left: 0;
  top: $menuMobileLandingHeight;
  height: calc(var(--vh, 1vh) * 100 - $menuMobileLandingHeight);
  box-shadow: inset 0 20px 20px -20px rgba($black, 0.2);
  width: 100%;
  transform: translateX(100%);
  transition: transform 0.2s ease;
  background: $cardBackgroundDark;

  @include above-breakpoint('extraSmall') {
    top: $menuDesktopHeight;
    height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
  }

  &--expanded {
    transform: translateX(0);
  }

  &__backdrop {
    position: fixed;
    z-index: 99;
    top: $menuMobileHeight;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 - $menuMobileHeight);

    @include above-breakpoint('extraSmall') {
      top: $menuDesktopHeight;
      height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
    }
  }
}

.nav-list {
  display: flex;
  flex-direction: column;
  padding: 50px;
  margin: 0;
  list-style: none;

  li {
    padding-right: 18px;
    padding-bottom: 36px;
    font-size: 16px;
    text-align: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      padding-bottom: 0;
    }

    @include above-breakpoint('mediumLarge') {
      padding-right: 40px;
    }
  }
}

.nav-list--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.currency-purchase {
  display: flex;
  justify-content: center;
  padding: 10px 0 35px 0;
}

.nav-market {
  color: $green;

  svg > path:first-child {
    fill: $green;
  }

  &__text {
    color: $green;
  }
}

.nav-bar_social__list {
  display: flex;
  align-items: center;
  padding: 0;
  list-style: none;
  gap: 20px;

  @include below-breakpoint('medium') {
    display: none;
  }

  .nav-bar__social___icon {
    padding-right: 10px;

    svg {
      fill: $blue;
      transition: fill 0.3s ease;
      height: 24px;
      width: 24px;

      &:hover {
        fill: $extraLightBlue;
      }
    }
  }
}

.banner-button {
  height: 38px;
  padding: 6px 24px;
  @include below-breakpoint('small') {
    display: none;
  }
}

.nav-text {
  font-weight: 600;
  font-size: 26px;
  text-transform: uppercase;
  line-height: 30px;
  color: $white;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;

  &__staking {
    color: $green;
  }

  img {
    margin-right: 10px;
  }

  &:hover,
  &.nav-text--active {
    color: $extraLightBlue;
    text-decoration: underline;
    text-underline-offset: 8px;
  }
}
