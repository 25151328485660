@import '../../../../styles/variables';

.probability-box {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    color: $light;
    text-transform: uppercase;
  }
}

.probability-row {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;

  &__title {
    flex-grow: 1;
    flex-basis: 0;
    font-size: 16px;
    line-height: 24px;
    color: $light;
    text-align: left;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    flex-grow: 1;
    flex-basis: 0;
    background-color: $cardBackgroundDark;
    border-radius: 100px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: $green;
  }
}
