@import 'styles/variables';
@import '../../../styles/breakpoints';

button.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: $light;
  width: 100%;
  padding: 16px 0;
  text-align: start;

  @include above-breakpoint('small') {
    padding: 20px 0;
    font-size: 24px;
    line-height: 28px;
  }

  span {
    transition: color 0.2s ease;
  }

  svg {
    width: 40px;
    min-width: 40px;
    color: $secondaryColor;
    transition: transform 0.2s ease, color 0.2s ease;
  }

  &--open {
    color: $green;

    svg {
      color: $light;
      transform: rotate(-45deg);
    }
  }
}

.content {
  padding-bottom: 16px;
  text-align: start;
  font-size: 15px;
  line-height: 22px;

  @include above-breakpoint('small') {
    font-size: 16px;
    line-height: 26px;
  }

  a {
    text-decoration: underline;
    margin: 0 5px;
    color: $secondaryColor;
  }
}
