@import 'styles/variables';
@import '../../../styles/breakpoints';
@import '../../../styles/animations/shine-animation';

.section {
  position: relative;
  z-index: 2;
  padding: 30px 0 50px 0;

  @include above-breakpoint('small') {
    padding: 40px 0 80px;
  }

  @include above-breakpoint('medium') {
    padding: 60px 0 120px;
  }
}

.content {
  position: relative;
  z-index: 2;
  width: 1375px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

h2.title {
  text-align: center;
  margin-bottom: 30px;

  @include above-breakpoint('small') {
    margin-bottom: 40px;
  }

  @include above-breakpoint('medium') {
    margin-bottom: 60px;
  }
}

.arrow {
  margin: 0 auto;
  margin-bottom: 30px;

  img {
    max-width: 100%;
    width: 897px;
  }
}

.videos-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @include above-breakpoint('small') {
    gap: 40px;
  }
}

.video-animation-container {
  position: relative;
  flex-basis: 290px;
  @include between-breakpoints('small', 'medium') {
    flex-basis: 200px;
  }
}

.video-container {
  height: 200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: $aboutColorSecondary;
    margin-top: 16px;
    text-transform: uppercase;
  }
}

.video-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.youtube-video {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.video-title,
.video-logo {
  position: relative;
  z-index: 1;
  max-width: calc(100% - 30px);
  margin: 0 auto;
}

.video-logo {
  width: 212px;
}

.video-modal__content {
  position: absolute;
  height: 100%;
  left: 0;
  width: 100%;
  top: 0;
  border-radius: 12px;
  overflow: hidden;
}
