@import 'styles/variables';

.checkmark-list-item {
  position: relative;
  padding-left: 35px;
  color: $light;

  &__icon {
    position: absolute;
    left: 0;
    top: 1px;
    width: 25px;
    height: 20px;
  }
}
