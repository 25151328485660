@import 'styles/variables';

.shares-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  line-height: 16px;
  color: $light;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 18px;
  text-align: center;
  background: $primaryColor;
}

.shares-icon {
  width: 18px;
}
