.fade-animation {
  opacity: 0;
}

.fade-animation.enter-done {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.fade-animation.exit {
  opacity: 1;
  transform: scale(1);
}

.fade-animation.exit-active {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.fade-scale-animation {
  opacity: 0;
  transform: scale(0.8);
}

.fade-scale-animation.enter-done {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-scale-animation.exit {
  opacity: 1;
  transform: scale(1);
}

.fade-scale-animation.exit-active {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.2s ease, transform 0.2s ease;
}
