@import 'styles/variables';
@import 'styles/breakpoints';

.modal {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;

    .backdrop {
      z-index: 10;
    }
  }

  &__title {
    position: relative;
    padding: 10px 20px;
    margin-bottom: 24px;
    color: $white;

    h2 {
      font-size: 32px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    button {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }

    &-icon {
      height: 16px;
      width: 16px;
      color: $light;
    }

    &-back-button {
      left: 0;
    }

    &-close-button {
      right: 0;

      &--outside.modal__title-close-button {
        right: -70px;
        top: -50px;
        @include below-breakpoint('medium') {
          right: 0;
          top: -45px;
        }
      }
    }
  }

  &__content {
    position: absolute;
    z-index: 11;
    animation: opacityAnimation 0.2s;
    animation-fill-mode: forwards;
    border-radius: 16px;
    padding: 15px 20px 20px;
    background-color: $cardBackgroundDark;
    max-height: calc(var(--vh, 1vh) * 90);
    overflow-y: auto;

    @media (min-width: $screenSize680px) {
      padding: 20px 40px 40px 40px;
    }
  }
}

@keyframes opacityAnimation {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
