@use 'sass:map';
@import 'styles/variables';

$handleBackground: map.get(map.get($buttonColorConfig, blue), 'background');

.rc-slider-handle {
  background: #{center / 25px 25px no-repeat url('../../assets/slider/slider-handler-icon.svg'), $handleBackground};
}

.rc-slider-handle:focus {
  box-shadow: 0 0 0 2px $secondaryColor;
}
