@import '../variables';

.about-page {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: $primaryColor;

  .about-background-image {
    width: 100%;
  }

  .socials-bar {
    z-index: 1;
  }

  &_heading {
    position: relative;
    margin-bottom: 0px;
    max-width: 100%;
    width: 100%;
    background-color: black;

    @media (min-width: $screenSize680px) {
      margin-bottom: 35px;
    }

    @media (min-width: $screenSize1381px) {
      margin-bottom: 0;
    }

    > img {
      max-width: 100%;
      width: 100%;

      height: 600px;
      object-fit: cover;

      @media (min-width: $screenSize900px) {
        height: 100%;
        object-fit: fill;
      }
    }

    .about_heading-middle-section-logo,
    .reality-nft-logo,
    .about-white-paper-wrapper,
    .middle-section-counter,
    &-middle-section {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: 100%;
      height: 100%;
    }

    &-middle-section {
      &-venue-card,
      &-board,
      .reality-nft-logo img,
      .about-white-paper-wrapper > div,
      .middle-section-counter .sale-counter-wrapper,
      .cloud-label {
        display: none;
        transform: scale(0.25);

        @media (min-width: 900px) {
          display: inline;
          transform: scale(0.25);
        }

        @media (min-width: 1100px) {
          transform: scale(0.35);
        }

        @media (min-width: 1250px) {
          transform: scale(0.45);
        }

        @media (min-width: 1400px) {
          transform: scale(0.55);
        }

        @media (min-width: 1658px) {
          transform: scale(0.65);
        }

        @media (min-width: 2116px) {
          transform: scale(0.85);
        }

        @media (min-width: 2800px) {
          transform: scale(1);
        }
      }

      &-board {
        position: absolute;
        bottom: 25.3%;
        left: 10vw;
        z-index: 2;
        transform-origin: left bottom;
        background-size: 100% 100%;
        padding: 25px;
        position: absolute;
        width: 570px;
        height: 670px;

        @media (min-width: 1250px) {
          right: calc(13vw);
        }

        p {
          margin: 40px;
          font-weight: bold;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #74fbff;
          text-shadow: 0px 0px 16px #09ceff;
          font-size: 50px;
          line-height: 58px;
        }
      }

      .reality-nft-logo {
        img {
          transform-origin: center bottom;
          width: 500px;
          position: absolute;
          left: calc(50% - 250px);
          bottom: 29%;

          @media (max-width: 899px) {
            display: inline;
            transform: scale(0.4);
          }
        }
      }

      .middle-section-counter .sale-counter-wrapper {
        transform-origin: center bottom;
        width: 700px;
        position: absolute;
        left: calc(50% - 350px);
        bottom: 21%;

        @media (max-width: 899px) {
          display: inline;
          transform: scale(0.4);
        }

        .counter-label {
          font-size: 40px;
          width: 100%;
        }

        .counter {
          height: 150px;
          border-radius: 100px;

          .value {
            font-size: 80px;
            line-height: 80px;
          }

          .label {
            font-size: 25px;
            line-height: 25px;
          }
        }
      }

      .about-white-paper-wrapper > div {
        justify-content: center;
        transform-origin: center bottom;
        width: 700px;
        position: absolute;
        left: calc(50% - 350px);
        bottom: 8%;
        display: flex;
        justify-content: center;

        @media (max-width: 899px) {
          display: flex !important;
          transform: scale(0.4);
        }

        p {
          font-weight: normal;
          font-size: 36px;
          line-height: 36px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #4987ff;
        }

        button {
          font-weight: bold;
          font-size: 22px;
          line-height: normal;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          color: #4987ff;
          border-radius: 30px;
          border: 3px solid #4987ff;
          margin-left: 30px;
          padding: 12px 18px;
          background-color: black;

          &:hover {
            transition: 0.2s;
            cursor: pointer;
            color: white;
            border: 2px solid white;
          }
        }
      }

      .cloud-label {
        transform-origin: center bottom;
        position: absolute;
        left: calc(50% - 154px);
        width: 310px;
        min-height: 150px;

        @media (max-width: 899px) {
          bottom: 76.5%;
          display: flex;
          transform: scale(0.4);
        }

        @media (min-width: 900px) {
          bottom: 81%;
          display: flex;
        }

        .cloud-label__heading div {
          margin-top: 10px;
          font-size: 35px;
        }

        .text_and_value_wrap {
          display: flex;
          align-items: flex-end;
          margin-top: 10px;

          .value_and_icon_value {
            font-size: 40px !important;
          }

          .value_and_icon_icon {
            width: 50px;
            height: 50px;
          }
        }

        .cloud-label__arrow {
          top: 80%;
          width: 50px;
          height: 50px;
          clip-path: polygon(80% 0%, 80% 0%, 100% 100%, 0% 80%);
        }
      }

      &-venue-card {
        position: absolute;
        bottom: 27%;
        right: calc(10vw);
        z-index: 2;
        transform-origin: right bottom;

        @media (min-width: 1250px) {
          right: calc(13vw);
        }

        &:after {
          content: 'DISCLAIMER: NOT ACTUAL NUMBERS, IT MAY VARY ONCE LAUNCHED';
          font-weight: normal;
          font-size: 12.5px;
          line-height: 100%;
          letter-spacing: 0.04em;
          color: #ffffff;
          opacity: 0.75;
          position: absolute;
          bottom: -30px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  &_sections > div {
    section {
      margin-top: -32px;
    }

    section:nth-child(1) {
      background-color: $primaryColor;
      z-index: 1;
      margin-top: -36px;
    }

    section:nth-child(2) {
      background-color: #143478;
      z-index: 2;

      > div:first-child {
        position: initial;

        .about_downloads {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          padding-bottom: 16px;

          @media (min-width: $screenSize1381px) {
            padding-bottom: 0;
          }

          img {
            max-width: 160px;
            max-height: 48px;
          }
        }
      }
    }

    section:nth-child(3) {
      background-color: #1f408c;
      z-index: 3;
    }

    section:nth-child(4) {
      background-color: #143478;
      z-index: 4;
    }

    section:nth-child(5) {
      background-color: $primaryColor;
      z-index: 5;
    }

    section:last-of-type {
      h1 {
        margin-bottom: 0.83em;
      }
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: black;
    width: 100%;
    padding-left: 39px;

    @media (min-width: $screenSize680px) {
      padding-left: 62px;
    }

    @media (min-width: $screenSize1381px) {
      clip-path: polygon(0 32px, 100% 0, 100% 100%, 0 100%);
      margin-top: -50px;
      height: 220px;
      z-index: 9;
    }

    p {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #4987ff;
    }

    button {
      font-weight: bold;
      font-size: 14px;
      line-height: normal;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #4987ff;
      border-radius: 20px;
      border: 2px solid #4987ff;
      margin-left: 30px;
      padding: 5px 12px;
      background-color: black;

      &:hover {
        transition: 0.2s;
        cursor: pointer;
        color: white;
        border: 2px solid white;
      }
    }

    img {
      width: 128px;
      height: 78px;
    }
  }
}
