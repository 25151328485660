@import 'styles/variables';

.notifications-portal {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  bottom: 0;
  z-index: 1000;
}

.notification {
  margin-bottom: 10px;
}
