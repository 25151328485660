@import '../../../styles/breakpoints';
@import 'styles/variables';

.section {
  padding: 50px 20px;
  background: #051943;

  @include above-breakpoint('extraSmall') {
    padding: 50px 20px;
  }
  @include above-breakpoint('small') {
    padding: 80px 20px;
  }
  @include above-breakpoint('mediumLarge') {
    padding: 120px 0;
  }
}

.container {
  @include above-breakpoint('medium') {
    margin-left: auto;
    margin-right: auto;
    width: 960px;
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;

    @include above-breakpoint('extraSmall') {
      font-size: 44px;
      line-height: 44px;
      margin-bottom: 40px;
    }
    @include above-breakpoint('small') {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 60px;
    }
  }
}

.faq-button {
  margin-top: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
