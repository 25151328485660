@keyframes pulse {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pulse-animation {
  animation: pulse 5s linear infinite;
}
