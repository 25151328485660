@import 'styles/variables';
@import '../../../styles/breakpoints';

.menu-element {
  position: relative;
  display: flex;
  align-items: center;
  color: $menuLinkColor;
  transition: color 0.3s ease-in-out;

  &:hover,
  &.menu-element--active {
    span {
      color: $turquoise;
    }
    svg {
      path {
        fill: $white;
      }
      path:first-child {
        fill: $turquoise;
      }
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 34px;
  min-height: 34px;
}

.text {
  color: $white;
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 10px;
}
