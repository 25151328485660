@import 'styles/variables';

.purchase-view {
  &__text {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__text-animated {
    font-family: 'IBM Plex Mono';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: $green;
  }
}
