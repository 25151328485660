@import '../../../styles/breakpoints';

.lootbox-amount-section {
  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;

    @include above-breakpoint('small') {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & &__close-button {
    align-items: flex-start;
    margin-right: -14px;
  }

  &__shares {
    min-width: 175px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &__section {
    width: 100%;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
  }

  &__button {
    padding-left: 32px;
    padding-right: 32px;
  }
}
