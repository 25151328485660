@import 'styles/variables';
@import '../../../styles/breakpoints';

.lootbox-section {
  &__info {
    margin: 16px 0 20px 0;

    @include below-breakpoint('small') {
      margin: 16px 0 30px 0;
    }

    a {
      text-decoration: underline;
      color: $secondaryColor;
    }
  }
}
