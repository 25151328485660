@import 'styles/variables';
@import '../../../styles/breakpoints';

.lootbox-success {
  display: none;
  opacity: 0;
  transition: 0.2s ease 0.2s;

  &--visible {
    opacity: 1;
    display: block;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    color: $light;
  }

  &__button {
    margin-top: 20px;
    width: 232px;
  }

  &__caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $light;
    margin-bottom: 30px;

    @include above-breakpoint('small') {
      gap: 14px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    text-transform: uppercase;

    @include above-breakpoint('small') {
      font-size: 48px;
      line-height: 80%;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;

    @include above-breakpoint('small') {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
