.container {
  transform: translateZ(0);
}

.slide-in-top {
  opacity: 0;
  transform: translateY(-40px);
  transition-property: transform, opacity;
  transition-timing-function: ease;

  &--animate-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-bottom {
  opacity: 0;
  transform: translateY(40px);
  transition-property: transform, opacity;
  transition-timing-function: ease;

  &--animate-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-bottom-without-fade {
  transform: scaleY(0);
  transition-timing-function: ease;
  transition-property: transform;

  &--animate-in {
    transform: scaleY(1);
  }
}

.slide-in-left {
  opacity: 0;
  transform: translateX(-40px);
  transition-property: transform, opacity;
  transition-timing-function: ease;

  &--animate-in {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-right {
  opacity: 0;
  transform: translateX(-40px);
  transition-property: transform, opacity;
  transition-timing-function: ease;

  &--animate-in {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: ease;

  &--animate-in {
    opacity: 1;
  }
}

.scale-in {
  opacity: 0;
  transform: scale(0.5);
  transition-property: transform, opacity;
  transition-timing-function: ease;

  &--animate-in {
    opacity: 1;
    transform: scale(1);
  }
}
