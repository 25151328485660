@import 'styles/variables';
@import '../../../styles/breakpoints';

.transaction-view {
  padding-top: 40px;
  padding-bottom: 100px;
  margin: 10px 0 30px 0;

  &__content--fade-out {
    display: none;
  }

  &__caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $light;

    @include above-breakpoint('small') {
      gap: 14px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    text-transform: uppercase;

    @include above-breakpoint('small') {
      font-size: 48px;
      line-height: 80%;
    }
  }

  &__subtitle {
    font-size: 26px;
    line-height: 28px;
    font-weight: 700;

    @include above-breakpoint('small') {
      font-weight: 700;
      font-size: 32px;
    }
  }

  &__content {
    margin: 0 auto;
  }

  &__lootbox {
    width: 260px;
    max-width: 100%;

    @include above-breakpoint('small') {
      width: 360px;
    }
  }

  &__text {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__text-animated {
    font-family: 'IBM Plex Mono';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: $green;
  }

  &__lootbox {
    margin: 0 auto;
  }

  &__lootbox > video {
    position: relative;
    width: 100%;
    padding-bottom: 0;
  }
}
