@import 'styles/variables';

button.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  height: 40px;
  border-radius: 32px;
  color: $tabsColor;
  margin: 4px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  &:first-child {
    margin-left: 4px;
  }

  &:last-child {
    margin-right: 4px;
  }

  &--active {
    background-color: $tabsActiveBackground;
    color: $tabsActiveColor;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
