@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.page {
  position: relative;

  @include above-breakpoint('small') {
    background-image: url('../../assets/redeem/redeem-page-background.svg');
    background-position: right 367px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.page-wrapper {
  width: 520px;
  max-width: 100%;
  padding-top: 0;

  &--wide {
    width: 638px;
  }

  @include above-breakpoint('small') {
    padding-top: 80px;
  }

  @include above-breakpoint('extraSmall') {
    padding-top: 50px;
  }
}

.connect-button {
  display: block;
  width: 100%;
  max-width: calc(100vw - 40px);
  margin: 0 auto 20px;
  text-transform: uppercase;
}

.claim-form-field {
  label {
    font-size: 24px;
  }

  label,
  input,
  input::placeholder {
    text-align: center;
  }
}

.claim-button {
  margin-top: 20px;
  width: 100%;
  text-transform: uppercase;
}
