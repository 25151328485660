@import 'styles/breakpoints';
@import 'styles/variables';

$bannerHeight: 740px;
$bannerHeightMobile: 620px;

.banner {
  position: relative;
  min-height: $bannerHeightMobile;

  @include above-breakpoint('medium') {
    min-height: $bannerHeight;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(15, 47, 152, 0) 40%, $primaryColor 100%);
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(32, 161, 255, 0.15), rgba(32, 161, 255, 0.15));
    opacity: 0.15;
  }
}

.main-text {
  margin-bottom: 50px;
  font-weight: 600;
  color: $light;
  font-size: 26px;
  line-height: 26px;
  text-transform: uppercase;

  @include above-breakpoint('small') {
    font-size: 32px;
    line-height: 42px;
  }

  @include above-breakpoint('medium') {
    font-size: 42px;
  }
}

.hint {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: $aboutColorSecondary;
  letter-spacing: 0.2em;
  margin-bottom: 20px;

  @include above-breakpoint('small') {
    font-size: 26px;
    line-height: 34px;
  }

  @include above-breakpoint('medium') {
    font-size: 32px;
    line-height: 42px;
  }
}

.banner-video {
  position: absolute;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translateX(-50%);
  object-fit: cover;
  object-position: top center;
  opacity: 0.45;
  z-index: 2;
}

.content {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100 - $menuMobileHeight);
  text-align: center;
  padding-top: 50px;

  @include above-breakpoint('extraSmall') {
    min-height: calc(var(--vh, 1vh) * 100 - $menuDesktopHeight);
  }

  @include above-breakpoint('small') {
    padding-top: 20px;
  }
}

.main-content {
  margin: auto;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.banner-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 206px;
}

.exchange-services {
  margin: 60px auto 20px;

  @include above-breakpoint('small') {
    margin: 100px auto 22px;
  }

  @include above-breakpoint('medium') {
    margin: 50px auto 33px auto;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 1640px;
    margin: 0 auto;
    padding: 0 10px;

    @include above-breakpoint('medium') {
      svg {
        height: 38px;
        width: auto;
      }

      gap: 70px;
    }

    @include above-breakpoint('extraLarge') {
      gap: 90px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0);
    }
  }

  &__caption {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: $aboutColorSecondary;
    letter-spacing: 0.2em;
    margin-bottom: 20px;
    padding: 0 30px;

    @include above-breakpoint('small') {
      margin-bottom: 30px;
    }

    @include above-breakpoint('medium') {
      margin-bottom: 50px;
    }
  }
}

.collections-services {
  margin: 12px auto 50px;

  @include above-breakpoint('small') {
    margin: 100px auto 80px;
  }

  @include above-breakpoint('medium') {
    margin: 24px auto 120px auto;
  }

  &__content {
    display: inline-flex;
    align-items: center;
    gap: 40px;
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    @include above-breakpoint('medium') {
      svg {
        height: 38px;
        width: auto;
      }

      gap: 70px;
    }

    @include above-breakpoint('extraLarge') {
      gap: 90px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0);
    }
  }

  &__caption {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: $aboutColorSecondary;
    letter-spacing: 0.2em;
    margin-bottom: 20px;
    padding: 0 30px;

    @include above-breakpoint('small') {
      margin-bottom: 30px;
    }

    @include above-breakpoint('medium') {
      margin-bottom: 50px;
    }
  }
}
