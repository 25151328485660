@import 'styles/variables';

.checkout-modal-success {
  text-align: center;

  &__details {
    margin: 38px 0;

    p {
      color: $white;
      margin: 0;
    }

    &-section:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__venue-container {
    display: flex;
    justify-content: center;
  }

  &__hash-link {
    text-decoration: underline;
    color: $green;
  }

  &__footer {
    display: flex;
    gap: 20px;
    margin-top: 38px;

    > button {
      min-width: 200px;
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
