@use 'sass:math';
@import '../../../styles/breakpoints';
@import 'styles/variables';

.lootbox-owned {
  &__slider {
    max-width: 100%;
    width: 100%;
    margin-right: -8px;
    padding-bottom: 40px;

    @include above-breakpoint('small') {
      margin-right: -20px;
    }

    :global {
      .slick-track {
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

  &__slider &__carousel-dots {
    bottom: 0px;
  }

  &__card {
    display: block;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;
    }
  }

  &__card-wrapper {
    margin: 0 20px;

    @include below-breakpoint('small') {
      margin: 0 8px;
    }
  }
}
